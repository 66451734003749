import React, { useEffect, useState } from "react";
import triangle from "../../src/assets/images/triangle.svg";
import heading1 from "../../src/assets/images/heading1.svg";
import triangle1 from "../../src/assets/images/big-triangle1.svg";
import triangle2 from "../../src/assets/images/big-triangle2.svg";
import down from "../../src/assets/icons/scroll-down.svg";
import point from "../../src/assets/images/point-image.svg";
import startup from "../../src/assets/icons/startup.svg";
import bulb from "../../src/assets/icons/bulb.svg";
import video from "../../src/assets/images/robot.svg";
import english from "../../src/assets/videos/english.mp4";
import amharic from "../../src/assets/videos/amharic.mp4";
import tigrinya from "../../src/assets/videos/tigrinya.mp4";
import play from "../../src/assets/icons/play-btn.svg";
import text1 from "../../src/assets/images/text1.svg";
import text2 from "../../src/assets/images/text2.svg";
import text3 from "../../src/assets/images/text3.svg";
import robot1 from "../../src/assets/images/robot-1.svg";
import robot2 from "../../src/assets/images/robot-2.svg";
import AI from "../../src/assets/images/AI.png";
import robot3 from "../../src/assets/images/robot-3.svg";
import AppHeader from "../@components/AppHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUser } from "../auth/AuthSlice";
import { Link } from "react-scroll";

const LandingPage = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const user = useSelector(selectUser);
  const [videoKey, setVideoKey] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const isLoggedIn = !!user.token; // convert token to boolean

  // useEffect(() => {
  //   const section = document.getElementById('faqs');
  //   if (section) {
  //     section.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, []);

  useEffect(() => {
    setVideoKey((prevKey) => prevKey + 1);
  }, [user.language]);

  return (
    <div>
      {/* <h1 style={{ color: "white" }}> {t("title")} </h1>
      <h2
        onClick={() => {
          i18n.changeLanguage("ar");
        }}
      >
        Change language
      </h2> */}
      <div className="container-fluid ">
        <AppHeader />
        <div className="bg-image">
          <div className="heading d-flex flex-column  w-50 ps-lg-5 ps-md-5 ps-sm-5 ps-2">
            <h1 className="text-white mt-5">Lampadina</h1>
            <img src={heading1} alt="" className="image1 " />
            <div className="logo d-flex flex-column justify-content-center ms-4 mt-2">
              <span>{t("heading")}</span>
            </div>
            <button
              type="button"
              className="btn register-btn rounded-pill p-0 mt-4 ms-4 "
              onClick={() => {
                navigate("/signup");
              }}
              disabled={isLoggedIn}
            >
              {t("signup")}
            </button>
          </div>
        </div>
        <section className="py-5 information ">
          <div className="down-btn d-flex justify-content-center pt-5 ms-3 mb-5">
            <div className="d-flex down-text  flex-column w-50">
              <p className="text-center fs-5">{t("info")}</p>
              <div className="d-flex justify-content-center mb-5">
                <Link to="play-video" spy={true} smooth={true}>
                  <a href="#play-video">
                    <img src={down} className="img-fluid" alt="..." />
                  </a>
                </Link>
              </div>
            </div>
          </div>

          <div className="what-is-this ms-3 pt-5">
            <div className="wit-heading mt-5">
              <h3 className="text-center ">{t("h3")}</h3>
              <p className="text-start w-50 mx-auto">{t("lampadinaAI")}</p>
            </div>
            {/* <div className="d-flex justify-content-between w-50 mx-auto wit-text ">
              <span className="text-white">{t("How Eugen.EI Works")}</span>
              <span className="text-white">{t("Asking Questions with Eugen.EI")}</span>
            </div> */}
            <div className="startup d-flex flex-row w-50 mx-auto  mt-1">
              <div className="me-3 w-50">
                <img src={startup} className="img-fluid" alt="..." />
              </div>
              <div>
                <span className="text-white ">{t("How Eugen.EI Works")}</span>
                <p className="text-white mt-1 lh-sm">
                  {t("Automated Q&A with Robots")}
                </p>
              </div>

              <div className="me-3 w-50">
                <img src={bulb} className="img-fluid" alt="..." />
              </div>
              <div>
                <span className="text-white ">
                  {t("Asking Questions with Eugen.EI")}
                </span>
                <p className="text-white mt-1 lh-sm">
                  {t("Choose Your Spoken Language")}
                </p>
              </div>
            </div>
          </div>
          <div className="point-image">
            <img src={point} className="img-fluid " alt="..." />
          </div>
          <div
            className="video  d-flex justify-content-center align-items-center"
            id="play-video"
          >
            <video
              controls
              className="img-fluid video-image mb-5"
              autoPlay
              key={videoKey}
            >
              {/* Conditionally set the video source based on the selected language */}
              {user.language === "ar" ? (
                <source src={amharic} type="video/mp4" />
              ) : user.language === "tr" ? (
                <source src={tigrinya} type="video/mp4" />
              ) : (
                <source src={english} type="video/mp4" />
              )}
              Your browser does not support the video tag.
            </video>
          </div>

          <section className="card-section">
            <div className="d-flex flex-column w-50 card-head text-center mx-auto">
              <div>
                {/* <img
                    src={text1}
                    className="img-fluid text1 my-2 "
                    alt="..."
                  /> */}
                <h2 className="text-white">{t("A.I")}</h2>
              </div>
              <div>
                {/* <img
                    src={text2}
                    className="img-fluid text2 my-2 "
                    alt="..."
                  /> */}
                <h1 className="text-white">{t("lampadina AI")}</h1>
              </div>
              <div>
                {/* <img
                    src={text3}
                    className="img-fluid text3 my-2 "
                    alt="..."
                  /> */}
                <h2 className="text-white">{t("future")}</h2>
              </div>
              <div>
                <h2
                  className="text-white up-events"
                  onClick={() => {
                    navigate("/upcoming-events");
                  }}
                >
                  {t("Upcoming Events")}
                </h2>
              </div>
            </div>
            <div className="container-fluid mt-5 px-lg-5">
              <div className="row ">
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-1 mb-2">
                  <div className="card h-100 w-100">
                    <img src={robot1} className="w-100 h-100" alt="..." style={{objectFit:"cover"}} />
                    <div className="card-body d-flex align-items-center ">
                      <div className="d-flex flex-row  justify-content-center align-items-center mx-auto justify-content-between w-100 card-left">
                        {/* <h1 className="date mb-0 mx-1">1</h1>
                        <p className="month mb-0 ms-1 mx-1">{t("dec")}</p>
                        <h1 className="card-title text-center mb-0 mx-1">
                          2023
                        </h1> */}
                        <h2 className="card-title text-center m-auto">
                          {t("comingsoon")}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-1 mb-2">
                  <div className="card h-100 w-100">
                    <img src={AI} className="w-100 h-100" style={{objectFit:"cover"}} alt="..." />
                    <div className="card-body d-flex align-items-center ">
                      <div className="d-flex flex-row  justify-content-center align-items-center mx-auto justify-content-between w-100 card-left">
                        {/* <h1 className="date mb-0 mx-1">1</h1>
                        <p className="month mb-0 ms-1 mx-1">{t("dec")}</p>
                        <h1 className="card-title text-center mb-0 mx-1">
                          2023
                        </h1> */}
                        <h1 className="card-title text-center m-auto">
                          {t("comingsoon")}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-1  mb-2">
                  <div className="card h-100 w-100">
                    <img src={robot3} className="w-100 h-100" style={{objectFit:"cover"}} alt="..." />
                    <div className="card-body d-flex align-items-center ">
                      <div className="d-flex flex-row  justify-content-center align-items-center w-75 mx-auto justify-content-between w-100  card-left">
                        {/* <h1 className="date mb-0 mx-1">1</h1>
                        <p className="month mb-0 ms-1 mx-1">{t("dec")}</p>
                        <h1 className="card-title text-center mb-0  mx-1">
                          2023
                        </h1> */}
                        <h1 className="card-title text-center m-auto">
                          {t("comingsoon")}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className="faqs  py-5 " id="faqs">
          <div className="container mt-5 ">
            <div className="accordion-heading">
              <h1 className="text-center">{t("Faqs")}</h1>
            </div>
            <div className="abcd mx-auto">
              <div className="tabs">
                <div className="tab">
                  <input type="checkbox" id="chck1" />
                  <label className="tab-label" htmlFor="chck1">
                    {t("question1")}
                  </label>
                  <div className="tab-content">{t("answer1")}</div>
                </div>
                <div className="tab">
                  <input type="checkbox" id="chck2" />
                  <label className="tab-label" htmlFor="chck2">
                    {t("question2")}
                  </label>
                  <div className="tab-content">{t("answer2")}</div>
                </div>

                <div className="tab">
                  <input type="checkbox" id="chck3" />
                  <label className="tab-label" htmlFor="chck3">
                    {t("question3")}
                  </label>
                  <div className="tab-content">{t("answer3")}</div>
                </div>
                <div className="tab">
                  <input type="checkbox" id="chck4" />
                  <label className="tab-label" htmlFor="chck4">
                    {t("question4")}
                  </label>
                  <div className="tab-content">{t("answer4")}</div>
                </div>
                <div className="tab">
                  <input type="checkbox" id="chck5" />
                  <label className="tab-label" htmlFor="chck5">
                    {t("question5")}
                  </label>
                  <div className="tab-content">{t("answer5")}</div>
                </div>
                <div className="tab">
                  <input type="checkbox" id="chck6" />
                  <label className="tab-label" htmlFor="chck6">
                    {t("question6")}
                  </label>
                  <div className="tab-content">{t("answer6")}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-3 mb-md-0 mb-4">
                <h2 className="footer-heading">
                  <a href="#" className="logo">
                    {t("Product")}
                  </a>
                </h2>
                <p className="list-item"></p>
              </div>
              <div className="col-md-6 col-lg-3 mb-md-0 mb-4">
                <h2 className="footer-heading">{t("Information")}</h2>
                <ul className="list-unstyled">
                  {/* <li>
                    <a href="#" className="py-1 d-block">
                      {t("Terms")}
                    </a>
                  </li> */}
                  <li
                    onClick={() => {
                      navigate("/privacy-policy");
                    }}
                  >
                    {/* <a href="" className="py-1 d-block"> */}
                    <span className="inform py-1 d-block">{t("Privacy")}</span>
                    {/* </a> */}
                  </li>
                  <li>
                    <a href="#" className="py-1 d-block"></a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-lg-3 mb-md-0 mb-4">
                <h2 className="footer-heading">{t("Company")}</h2>
                <ul className="list-unstyled">
                  <li>
                    <a href="/term-and-service" className="py-1 d-block">
                      {t("terms and conditions")}
                    </a>
                  </li>
                  {/* <li>
                    <a href="#" className="py-1 d-block">
                      {t("Great revenge")}{" "}
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="col-md-6 col-lg-3 mb-md-0 mb-4">
                <h2 className="footer-heading">{t("Subscribe")}</h2>
                <form action="#" className="subscribe-form">
                  <div className="form-group d-flex">
                    <input
                      type="text"
                      className="form-control w-75 rounded-left"
                      placeholder={t("Email address") ?? ""}
                    />
                    <button
                      onClick={() => {
                        navigate("/subscription");
                      }}
                      type="submit"
                      className="form-control submit rounded-right"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="15"
                        viewBox="0 0 9 15"
                        fill="none"
                      >
                        <path
                          d="M1.22437 13.9741L7.22437 7.97412L1.22437 1.97412"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="w-100 mt-5  py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-sm-6 col-6">
                  <ul className="list-group terms list-group-horizontal">
                    {/* <li
                      className="list-group-item"
                      onClick={() => {
                        navigate("/term-and-service");
                      }}
                    >
                      {t("Terms")}
                    </li> */}
                    {/* <li
                      className="list-group-item"
                      onClick={() => {
                        navigate("/about-us");
                      }}
                    >
                      {t("Privacy")}
                    </li> */}
                    {/* <li className="list-group-item">{t("Cookies")}</li> */}
                  </ul>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6 col-12 text-md-right d-flex justify-content-end">
                  <ul className="list-group social-icon list-group-horizontal pe-4">
                    <li className=" social-icon-items mx-1 d-flex justify-content-center align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                      >
                        <path
                          d="M14.289 7.16895C15.6151 7.16895 16.8869 7.69573 17.8246 8.63341C18.7622 9.57109 19.289 10.8429 19.289 12.1689V18.0023H15.9557V12.1689C15.9557 11.7269 15.7801 11.303 15.4675 10.9904C15.155 10.6779 14.7311 10.5023 14.289 10.5023C13.847 10.5023 13.4231 10.6779 13.1105 10.9904C12.798 11.303 12.6224 11.7269 12.6224 12.1689V18.0023H9.28903V12.1689C9.28903 10.8429 9.81582 9.57109 10.7535 8.63341C11.6912 7.69573 12.9629 7.16895 14.289 7.16895V7.16895Z"
                          fill="white"
                        />
                        <path
                          d="M5.95568 8.00195H2.62234V18.002H5.95568V8.00195Z"
                          fill="white"
                        />
                        <path
                          d="M4.28901 5.50228C5.20949 5.50228 5.95568 4.75609 5.95568 3.83561C5.95568 2.91514 5.20949 2.16895 4.28901 2.16895C3.36854 2.16895 2.62234 2.91514 2.62234 3.83561C2.62234 4.75609 3.36854 5.50228 4.28901 5.50228Z"
                          fill="white"
                        />
                      </svg>
                    </li>
                    <li className=" social-icon-items mx-1 d-flex justify-content-center align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="18"
                        viewBox="0 0 11 18"
                        fill="none"
                      >
                        <path
                          d="M10.0297 0.981689H7.5297C6.42463 0.981689 5.36483 1.42068 4.58343 2.20208C3.80202 2.98348 3.36304 4.04329 3.36304 5.14836V7.64836H0.863037V10.9817H3.36304V17.6484H6.69637V10.9817H9.19637L10.0297 7.64836H6.69637V5.14836C6.69637 4.92734 6.78417 4.71538 6.94045 4.5591C7.09673 4.40282 7.30869 4.31502 7.5297 4.31502H10.0297V0.981689Z"
                          fill="white"
                        />
                      </svg>
                    </li>
                    <li className=" social-icon-items mx-1 d-flex justify-content-center align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};
export default LandingPage;
