import React, { useEffect, useRef, useState } from "react";
import AppHeader from "../@components/AppHeader";
import { resendEmail } from "../@api/auth";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

const VerifyEmail = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timerDuration, setTimerDuration] = useState(30);
  const intervalRef = useRef<number>();
  const [hover, setHover] = useState(false);

  const startTimer = () => {
    setIsButtonDisabled(true);

    // Use the intervalRef to hold the interval ID
    intervalRef.current = window.setInterval(() => {
      setTimerDuration((prevDuration) => Math.max(prevDuration - 1, 0));
    }, 1000);

    // Clear the interval when the timer expires
    setTimeout(() => {
      clearInterval(intervalRef.current);
      setIsButtonDisabled(false);
      setTimerDuration(30);
    }, 30000);
  };

  const handleResendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsButtonDisabled(true);
      startTimer();
      // Make the API call with the stored email from local storage
      const response = await resendEmail({
        email: localStorage.getItem("email") || "", // Retrieve the email from local storage
      });

      // Handle the response accordingly
      if (response.status === 200) {
        // API call successful, handle the response data
        toast.success(response.data.message, {
          iconTheme: { primary: "#ef09a1de", secondary: "" },
        });
      } else {
        // API call failed, handle the error
        console.log(response.data.error);
      }
    } catch (error) {
      // Handle any other errors that might occur during the API call
      console.error(error);
    }
  };

  useEffect(() => {
    if (timerDuration == 0) {
      setIsButtonDisabled(false);
      setTimerDuration(30);
      // setHover(false);
    }
  }, [timerDuration]);

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  return (
    <div>
      <section className="verify-email">
        {/* <div className="upcoming-events-heading">
          <h1 className="text-white ms-3 mt-3">Upcoming Events</h1>
        </div> */}

        <div className="verify-box d-flex align-items-center h-100">
          <div className="container">
            <div className="row  justify-content-center">
              <div className="col-md-8">
                <div className="card h-100 w-100">
                  {/* <img src={robot3} className="" alt="..." /> */}
                  <div className="card-body border-radius-0 text-center p-5">
                    <h6 className="text-white fw-bold">Please Verify Email</h6>
                    <p className="text-white">We send an email</p>

                    <p className="text-white">
                      Just click on the link in that email to complete your
                      signup.
                      <br />
                      If you don't see it, you may need to check your spam
                      folder.
                    </p>
                    <p className="text-white">Still can't find the email?</p>
                    <button
                      className="verify-btn w-25"
                      onClick={handleResendEmail}
                      disabled={isButtonDisabled}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => !isButtonDisabled && setHover(false)}
                      style={{
                        backgroundColor: hover ? "#db1d9c" : "#ef09a1",
                        cursor: hover ? "pointer" : "",
                      }}
                    >
                      {isButtonDisabled
                        ? `Resend in ${timerDuration}s`
                        : "Resend Email"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default VerifyEmail;
