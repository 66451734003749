import axios from "axios";
// import { toast } from 'react-toastify';
import { toast } from "react-hot-toast";
import { store } from "../app/store";
import { logout } from "../auth/AuthSlice";

export const apiController = axios.create({
  baseURL: "https://backend.lampadina.ai/",
  // baseURL:"https://fd6e-2407-d000-b-edba-f0ef-9772-4165-e6a8.ngrok-free.app/"
  // baseURL:"http://192.168.18.125:8099/"
});

apiController.interceptors.request.use((request: any) => {
  request.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return request;
});


apiController.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      toast.error("Your session has expired. Please log in again.");

      setTimeout(() => {
        store.dispatch(logout());
        window.location.href = "/login";
      }, 1000);
    }

    return Promise.reject(error);
  }
);
