import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectUser } from "../auth/AuthSlice";
import { resetPassword } from "../@api/auth";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

type UserResetPassword = {
  password: string;
  confirmPassword: string;
};

const ResetPassword = () => {
  const validationSchema = Yup.object().shape({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string().required("Confirm Password is required"),
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserResetPassword>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: UserResetPassword, e: any) => {
    if (user?.isLoggedIn || !searchParams.get("token")) {
      navigate("/");
    }
    const token = searchParams.get("token");
    const { password } = data;

    resetPassword({ token, password })
      .then((response) => {
        toast.success(response.data.detail);
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error reset password:", error);
        toast.error("An error occurred while rest the password.");
      });
  };
  return (
    <div>
      <section className="login-page">
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="upcoming-events-heading">
          <h1 className="text-white ms-3 mt-3">Upcoming Events</h1>
        </div> */}

          <div className="verify-box d-flex align-items-center h-100">
            <div className="container">
              <div className="row  justify-content-center">
                <div className="col-md-6">
                  <div className="card h-100 w-100">
                    {/* <img src={robot3} className="" alt="..." /> */}
                    <div className="card-body border-radius-0 text-center p-5">
                      <h6 className="text-white fw-bold">
                        Create New Password
                      </h6>
                      <p className="text-white">
                        Your New Password must be different from previously used
                        passwords
                      </p>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text d-flex justify-content-center align-items-center"
                          id="basic-addon1"
                        >
                          <i className="fa-solid fa-lock"></i>
                        </span>
                        <input
                          type="password"
                          // className="form-control password"
                          placeholder="Enter Password"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                          // onChange={handleResetChange}
                          //   value={password}
                          {...register("password")}
                          className={`form-control position-relative password ${
                            errors.password ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback position-absolute">
                          {errors.password?.message}
                        </div>
                      </div>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text d-flex justify-content-center align-items-center"
                          id="basic-addon1"
                        >
                          <i className="fa-solid fa-lock"></i>
                        </span>
                        <input
                          type="password"
                          // className="form-control password"
                          placeholder="Confirm Password"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                          // onChange={handleResetConfirm}
                          //   value={confirmPassword}
                          {...register("confirmPassword")}
                          className={`form-control position-relative password  ${
                            errors.confirmPassword ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback position-absolute">
                          {errors.confirmPassword?.message}
                        </div>
                      </div>
                      <button className="verify-btn w-75">
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default ResetPassword;
