import React from "react";
import AppHeader from "../@components/AppHeader";
import { useTranslation } from "react-i18next";

const TermsAndServicePage = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <AppHeader />
      <section className="terms-condition">
        <div className="terms-condition-heading">
          <h1 className="text-white ms-3 mt-3">Terms & Services</h1>
        </div>

        <div className="terms-condition-description">
          <div className="container">
            <div className="row border-term">
              <div className="col-lg-6  border-term">
                <ul className="list-group term-list">
                  <li className="list-group-item term-list-items">
                    <span className="term-heading">
                      <h3 className="under-line1">Acceptance of Terms</h3>
                    </span>
                    <p>
                      By using our AI chatbot site, you agree to be bound by
                      these Terms and Conditions. If you do not agree with any
                      part of these terms, you must not use our services.
                    </p>
                  </li>

                  <li className="list-group-item term-list-items">
                    <span className="term-heading">
                      <h3 className="under-line1">Eligibility</h3>
                    </span>
                    <p>
                      To access and use our AI chatbot site, you must be at
                      least 13 years old or the age of majority in your
                      jurisdiction, whichever is higher. By using our site, you
                      represent that you meet this age requirement and comply
                      with all applicable local, national, and international
                      laws and regulations.
                    </p>
                  </li>

                  <li className="list-group-item term-list-items">
                    <span className="term-heading">
                      <h3 className="under-line1">User Conduct</h3>
                    </span>
                    <p>
                      You agree not to use our AI chatbot site for any unlawful
                      purpose or to engage in any conduct that may harm,
                      interfere with, or disrupt the site or its users. You are
                      solely responsible for your interactions with the chatbot
                      and any content you provide.
                    </p>
                  </li>

                  <li className="list-group-item term-list-items">
                    <span className="term-heading">
                      <h3 className="under-line1">
                        Intellectual Property Rights
                      </h3>
                    </span>
                    <p>
                      All content on our AI chatbot site, including but not
                      limited to text, graphics, logos, and software, is the
                      property of the site or its licensors and is protected by
                      applicable copyright, trademark, and other intellectual
                      property laws. You agree not to reproduce, distribute, or
                      create derivative works based on our content without our
                      prior written consent.
                    </p>
                  </li>

                  <li className="list-group-item term-list-items">
                    <span className="term-heading">
                      <h3 className="under-line1">
                        API Usage and Information Control
                      </h3>
                    </span>
                    <p>
                      Our AI chatbot relies on third-party APIs for its
                      functionality. We do not control or endorse the
                      information provided by these APIs, and we are not
                      responsible for any errors, inaccuracies, or omissions in
                      the content they provide.
                    </p>
                  </li>
                  <li className="list-group-item term-list-items">
                    <span className="term-heading w-auto">
                      <h3 className="under-line1">Limitation of Liability</h3>
                    </span>
                    <p>
                      While we strive to provide accurate translations and
                      information, our AI chatbot may occasionally produce
                      errors or incorrect translations. We do not guarantee the
                      accuracy, completeness, or reliability of the translations
                      and information provided by our chatbot. By using our
                      chatbot, you acknowledge and agree that we shall not be
                      liable for any damages or losses resulting from
                      inaccuracies or errors in the content provided.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 border-term">
                <li className="list-group-item term-list-items">
                  <span className="term-heading">
                    <h3 className="under-line1">Indemnification</h3>
                  </span>
                  <p>
                    You agree to indemnify and hold us harmless from any claims,
                    damages, losses, or expenses (including reasonable
                    attorneys' fees) arising from your violation of these terms,
                    your use of our AI chatbot site, or any content you submit
                    or provide through the site.
                  </p>
                </li>
                <span className="Subscription-Term-heading">
                  <h1 className="under-line">Subscription Terms</h1>
                </span>

                <ul className="list-group subscription-term-list">
                  <li className="list-group-item subscription-term-list-items">
                    <span className="subscription-term-heading">
                      <h3 className="under-line1">Subscription Duration</h3>
                    </span>
                    <p>
                      All subscriptions are valid for a period of one (1) year
                      from the date of purchase, unless otherwise specified.
                      Subscriptions will automatically renew for an additional
                      year unless canceled by the subscriber as per the Renewal
                      and Termination provisions stated below.
                    </p>
                  </li>
                  <span className="Subscription-Term-heading">
                    <h1 className="under-line">Renewal and Termination</h1>
                  </span>

                  <li className="list-group-item subscription-term-list-items">
                    <span className="subscription-term-heading">
                      <h3 className="under-line1">Automatic Renewal</h3>
                    </span>
                    <p>
                      Subscriptions will automatically renew for an additional
                      one (1) year term at the end of the initial subscription
                      period, unless the subscriber cancels the subscription in
                      accordance with the cancellation procedure outlined below.
                    </p>
                  </li>

                  <li className="list-group-item subscription-term-list-items">
                    <span className="subscription-term-heading">
                      <h3 className="under-line1">Cancellation Procedure</h3>
                    </span>
                    <p>
                      To cancel a subscription, subscribers must notify us in
                      writing at least thirty (30) days before the end of their
                      current subscription period. Upon receiving a valid
                      cancellation request, we will not renew the subscription,
                      and it will expire at the end of the current term.
                    </p>
                  </li>

                  <li className="list-group-item subscription-term-list-items">
                    <span className="subscription-term-heading">
                      <h3 className="under-line1">Refund Policy</h3>
                    </span>
                    <p>
                      We do not offer refunds for any subscription fees already
                      paid. However, subscribers may continue to access our
                      services until the end of their current subscription
                      period, even if they have requested a cancellation
                    </p>
                  </li>

                  <li className="list-group-item subscription-term-list-items">
                    <span className="subscription-term-heading">
                      <h3 className="under-line1">
                        Modifications to the Service
                      </h3>
                    </span>
                    <p>
                      We reserve the right to modify or discontinue our AI
                      chatbot site and its features at any time without prior
                      notice. We will not be liable for any modification,
                      suspension, or discontinuation of the site or any part
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TermsAndServicePage;
