import { apiController } from "../baseUrl";

export const loginApi = async (data: any) => {
  const response = await apiController.post("/auth/token", data);
  return response;
};

export const logoutApi = async () => {
  const response = await apiController.post("/auth/logout");
  return response;
};

export const signUpApi = async (data: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  DoB: string;
}) => {
  debugger;
  const response = await apiController.post("/user/add", data);
  return response;
};

export const getChatApi = async (authToken: any) => {
  const response = await apiController.get("chat/user");
  return response;
};

export const updateChatApi = async (data: {
  id: string;
  conversation: any;
}) => {
  const response = await apiController.post(`/chat/existing`, data);
  return response;
};

export const deleteChatHistory = async (data: { id: number }) => {
  const response = await apiController.post(`/chat/delete`, data);
  return response;
};

// export const createCheckoutSession = async (data: { payment: number }) => {
//   const response = await apiController.post(
//     `/api/create-checkout-session`,
//     data
//   );
//   return response;
// };
export const createCheckoutSession = async (data: { payment: number }) => {
    const response = await apiController.post(
      `/create-checkout-session`,
      data
    );
    return response;
  };

export const unsubsribe = async ()=>{
  const response = await apiController.post(
    `/unsubscribe_subscription`
  )
  return response;
}

export const checkoutSuccess = async()=>{

  const response = await apiController.post(
    `/checkout_success`,
    
  )
  return response
}
export const authenticatePayment = async (data: {
  stipeId: string;
  language: string;
  key?: number;
}) => {
  const response = await apiController.post(`/api/add_payment`, data);
  return response;
};

export const verifyEmail = async (authToken: any) => {
  const response = await apiController.post(
    `/user/verify?token=` + authToken.token
  );
  return response;
};

export const resendEmail = async (data: { email: string }) => {
  const response = await apiController.post(`/user/resend_token`, data);
  return response;
};

export const forgotPassword = async (data: { email: string }) => {
  const response = await apiController.post(`/user/forget`, data);
  return response;
};

export const resetPassword = async (data: { password: any; token: any }) => {
  const response = await apiController.post(`/user/reset_password`, data);
  return response;
};

export const trialApi = async (authToken: any) => {
  const response = await apiController.post("/api/trial");
  return response;
};
