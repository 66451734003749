import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  firstName: "",
  language: "tr",
  username: "",
  password: "",
  homeLanguage: "tr",
};

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, { payload: { user } }) => {
      console.log(user, "user")
      localStorage.setItem("token", user.token);
      state.token = user.token;
      state.firstName = user.firstName;
      state.Status = user.Status;
      state.verified = user.verified;
      state.trial = user.trial;
    },
    logout: (state) => {
      state.token = "";
      state.firstName = "";
      state.trial = "";
      localStorage.setItem("token", "");
    },
    initiateTrial: (state, { payload: { trial } }) => {
      state.trial = trial;
    },
    updateHomepageLanguage: (state, { payload: { homeLanguage } }) => {
      state.homeLanguage = homeLanguage;
    },
    register: (state, { payload: { user } }) => {
      localStorage.setItem("token", user.token);
      state.username = user.username;
    },
    updateLanguage: (state, { payload: { language } }) => {
      state.language = language;
    },
    rememberMe: (state, { payload: { username, password } }) => {
      state.username = username;
      state.password = password;
    },
  },
});

export const {
  login,
  logout,
  updateLanguage,
  rememberMe,
  initiateTrial,
  updateHomepageLanguage,
} = authSlice.actions;

export default authSlice.reducer;

export const selectUser = (state) => state.user;
export const selectLanguage = (state) => state.language;
