import React from "react";
import triangle from "../../src/assets/images/triangle.svg";
import logo from "../../src/assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, updateLanguage } from "../auth/AuthSlice";
import { useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

export default function AppHeader(props: any) {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const user = useSelector(selectUser);
  const authToken = user.token;

  const updateLang = (lang: string) => {
    dispatch(updateLanguage({ language: lang }));
  };

  // const fetchChatApi = async () => {
  //   try {
  //     const response = await getChatApi(authToken);
  //     const chatId = response?.data?.id
  //     setChatData(status);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <div className="sticky-top">
      <nav className="navbar navbar-expand-md navbar-light py-3 ">
        <div className="container-fluid nav-container">
          <a
            className="navbar-brand"
            href="#"
            onClick={() => {
              navigate("/");
            }}
          >
            <div className="tri-logo d-flex justify-content-start ">
              <img src={logo} alt="triangle" className="my-auto" />
            </div>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fa-solid fa-bars list-icon"></i>
          </button>
          <div
            className=" collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <div className="d-flex justify-content-end w-100">
              <div className="w60 d-flex justify-content-between">
                <ul className="navbar-nav nav-list  d-flex flex-row flex-wrap  mb-2 mb-lg-0">
                  <li className="nav-item nav-list-items px-2">
                    <a
                      className="nav-link fs-5"
                      aria-current="page"
                      href="#"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      {t("Home")}
                    </a>
                  </li>
                  <li className="nav-item nav-list-items px-2">
                    <a
                      className="nav-link fs-5"
                      href="#"
                      onClick={() => {
                        navigate("/subscription");
                      }}
                    >
                      {t("our service")}
                    </a>
                  </li>
                  <li className="nav-item nav-list-items px-2">
                    <a
                      className="nav-link fs-5"
                      onClick={() => {
                        navigate("/about-us");
                      }}
                      href="#"
                    >
                      {t("about us")}
                    </a>
                  </li>
                </ul>
                <form className="d-flex">
                  <ul className=" hdr-list list-group list-group-horizontal">
                    <li className="hdr-list-items list-group-item ms-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                        className="mt-1 me-1"
                      >
                        <path
                          d="M12.3369 22C17.8598 22 22.3369 17.5228 22.3369 12C22.3369 6.47715 17.8598 2 12.3369 2C6.81407 2 2.33691 6.47715 2.33691 12C2.33691 17.5228 6.81407 22 12.3369 22Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M2.33691 12H22.3369"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.3369 2C14.8382 4.73835 16.2597 8.29203 16.3369 12C16.2597 15.708 14.8382 19.2616 12.3369 22C9.83563 19.2616 8.41416 15.708 8.33691 12C8.41416 8.29203 9.83563 4.73835 12.3369 2V2Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>
                    <li className="hdr-list-items list-group-item">
                      <div className="dropdown drp-btn my-auto mt-1 me-2">
                        <button
                          className="btn py-0 dropdown-toggle rounded-pill  "
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {t("language")}
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li
                            onClick={() => {
                              updateLang("en");
                              i18n.changeLanguage("en");
                            }}
                          >
                            <a className="dropdown-item">English</a>
                          </li>
                          <li
                            onClick={() => {
                              updateLang("tr");
                              i18n.changeLanguage("tr");
                            }}
                          >
                            <a className="dropdown-item">Tigrinya</a>
                          </li>
                          <li
                            onClick={() => {
                              updateLang("ar");
                              i18n.changeLanguage("ar");
                            }}
                          >
                            <a className="dropdown-item">Amharic</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li className="hdr-list-items list-group-item">
                      {!authToken && (
                        <button
                          type="button"
                          className="btn py-0 hdr-btn rounded-pill mt-1"
                          onClick={() => {
                            navigate("/login");
                          }}
                        >
                          {t("enter")}
                        </button>
                      )}
                      {authToken && location.pathname !== "/chat-bot" && (
                        <button
                          type="button"
                          className="btn py-0 hdr-btn rounded-pill mt-1"
                          onClick={() => {
                            navigate("/chat-bot");
                            user.Status !== "Paid" &&
                              user.trial !== "y" &&
                              toast.error("Please buy subscription");
                          }}
                        >
                          {t("chat")}
                        </button>
                      )}
                      {authToken && location.pathname === "/chat-bot" && (
                        <button
                          type="button"
                          className="btn py-0 hdr-btn rounded-pill mt-1"
                          onClick={() => props.handleDeletion()}
                        >
                          Delete Chat
                        </button>
                      )}
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
