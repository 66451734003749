import { useSelector } from "react-redux";
import { selectUser } from "../auth/AuthSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { verifyEmail } from "../@api/auth";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

const VerifyRegistration = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    if (user?.isLoggedIn || !searchParams.get("token")) {
      navigate("/");
    }
    const token = searchParams.get("token");

    verifyEmail({ token })
      .then((response) => {
        if (response?.data?.status === true) {
          toast.success("Email verified successfully!", {
            iconTheme: { primary: "#ef09a1de", secondary: "" },
          });
          navigate("/login");
        } else {
          toast.error("Email verification failed!");
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error verifying email:", error);
        toast.error("An error occurred while verifying the email.");
        navigate("/");
      });
  }, []);

  return <div></div>;
};

export default VerifyRegistration;
