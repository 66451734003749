import React from "react";
import robot1 from "../../src/assets/images/robot-1.svg";
import robot2 from "../../src/assets/images/robot-2.svg";
import robot3 from "../../src/assets/images/robot-3.svg";
import AppHeader from "../@components/AppHeader";

const UpcomingEventsPage = () => {
  return (
    <div>
      <AppHeader />
      <section className="upcoming-events">
        <div className="upcoming-events-heading">
          <h1 className="text-white ms-3 mt-3">Upcoming Events</h1>
        </div>

        <div className="upcoming-events-box d-flex align-items-center h-100">
          <div className="container">
            <div className="row row-cols-1 row-cols-md-3 g-4">
              <div className="col">
                <div className="card h-100 w-100">
                  <img src={robot1} className="" alt="..." />
                  <div className="card-body d-flex align-items-center ">
                    <div className="d-flex flex-row  justify-content-center align-items-center justify-content-between px-lg-5 px-md-2 px-sm-5 px-2 w-75 mx-auto card-left">
                      <h1 className="date mb-0  mx-1">1</h1>
                      <p className="month mb-0 ms-1  mx-1">DEC</p>
                      <h1 className="card-title text-center mb-0  mx-1">
                        2023
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100 w-100">
                  <img src={robot2} className="" alt="..." />
                  <div className="card-body d-flex align-items-center ">
                    <div className="d-flex flex-row  justify-content-center align-items-center justify-content-between px-lg-5 px-md-2 px-sm-5 px-2  w-75 mx-auto card-left">
                      <h1 className="date mb-0  mx-1">1</h1>
                      <p className="month mb-0 ms-1  mx-1">DEC</p>
                      <h1 className="card-title text-center mb-0  mx-1">
                        2023
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card h-100 w-100">
                  <img src={robot3} className="" alt="..." />
                  <div className="card-body d-flex align-items-center ">
                    <div className="d-flex flex-row  justify-content-center align-items-center justify-content-between px-lg-5 px-md-2 px-sm-5 px-2  w-75 mx-auto card-left">
                      <h1 className="date mb-0  mx-1">1</h1>
                      <p className="month mb-0 ms-1  mx-1">DEC</p>
                      <h1 className="card-title text-center mb-0  mx-1">
                        2023
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default UpcomingEventsPage;
