import React, { useEffect, useState } from 'react'
import { checkoutSuccess } from '../@api/auth'
import { useNavigate } from 'react-router-dom'
const SuccessPage = () => {

    const navigate = useNavigate()
    useEffect(() => {
        checkOut()
    }, [])

    const checkOut = async () => {
        try {
            const response = await checkoutSuccess()
            timeOut()

        }
        catch (e) {
            console.log(e)
        }
    }
    const timeOut = () => {
        setTimeout(() => {
            navigate('/chat-bot')
        }, 3000)
    }
    return  (
        <div style={{
        //   backgroundColor: '#f0f0f0',
        //   fontFamily: 'Arial, sans-serif',
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'center'
        }}>
          <div style={{
            borderRadius: '20px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
            padding: '40px',
            backgroundColor: '#fafafa',
            maxWidth: '500px'
          }}>
            <h2 style={{
              fontSize: '28px',
              fontWeight: 'bold',
              marginBottom: '20px',
              color: '#333'
            }}>Subscription Successful!</h2>
            <p style={{
              fontSize: '18px',
              color: '#555',
              marginBottom: '30px'
            }}>Thank you for subscribing to our service. You're all set to explore our platform.</p>
            <a href="chat-bot" style={{
              backgroundColor: '#4caf50',
              color: '#ffffff',
              textDecoration: 'none',
              borderRadius: '8px',
              padding: '12px 30px',
              fontSize: '18px',
              display: 'inline-block',
              transition: 'background-color 0.3s',
              boxShadow: '0px 4px 15px rgba(76, 175, 80, 0.2)'
            }}>Go to Dashboard</a>
          </div>
        </div>
      );
}

export default SuccessPage