import React from 'react'

function CancelPage() {
  return (
    <div style={{
        fontFamily: 'Arial, sans-serif',
        marginTop: '50px',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <div style={{
          borderRadius: '20px',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          padding: '40px',
          backgroundColor: '#fafafa',
          maxWidth: '500px'
        }}>
          <h2 style={{
            fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '20px',
            color: '#d9534f'
          }}>Subscription Failed</h2>
          <p style={{
            fontSize: '18px',
            color: '#555',
            marginBottom: '30px'
          }}>We're sorry, but there was an issue processing your subscription. Please try again later or contact support for assistance.</p>
          <a href="subscribe.html" style={{
            backgroundColor: '#d9534f',
            color: '#ffffff',
            textDecoration: 'none',
            borderRadius: '8px',
            padding: '12px 30px',
            fontSize: '18px',
            display: 'inline-block',
            transition: 'background-color 0.3s',
            boxShadow: '0px 4px 15px rgba(217, 83, 79, 0.2)'
          }}>Try Again</a>
        </div>
      </div>
  )
}

export default CancelPage