import React, { useEffect, useRef, useState } from 'react';

const AutoScrollContainer: React.FC<any> = ({ children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [showScroll, setShowScroll] = useState(true)

    useEffect(() => {
        toggleScroll();
        console.log("Auto Scroll Called");
    }, [children]); // Trigger scroll effect when children change

    const toggleScroll = (): void => {
        if (containerRef.current) {
            const container = containerRef.current;
            const { scrollHeight } = container;
            container.scrollTop = scrollHeight; // Always scroll to the bottom
        }
    };

    const handleScroll = (): void => {
        if (containerRef.current) {
            const container = containerRef.current;
            const { scrollTop, scrollHeight, clientHeight } = container;

            const currentPos = Math.round(scrollTop)
            console.log(currentPos, scrollHeight, clientHeight, currentPos >= scrollHeight - clientHeight)

            setShowScroll(currentPos >= scrollHeight - clientHeight);

            console.log(showScroll, "Scroll")
        }
    };

    return (
        <div className='scrollbar' style={{ position: 'relative', overflowY: 'auto', maxHeight: '400px' }} ref={containerRef} onScroll={handleScroll} id="style-1">

            {!showScroll ? <svg onClick={toggleScroll} style={{ position: 'sticky', top: 50 , left:15 }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-down-circle-fill text-white" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z" />
            </svg> : ""}
            <div style={{ paddingBottom: '30px' }}>
                {children}
            </div>
        </div>
    );
};

export default AutoScrollContainer;
