import AppHeader from '../@components/AppHeader'


const PrivacyPolicy = () => {
  return (
    <div>
         <AppHeader/>
      <section className="privacy ">
        <div className="privacy-policy-heading   ps-3 ">
          <h1 className=" my-3 ps-1 under-line1">Privacy Policy</h1>
        </div>
        <div className="container privacy-description ">
          <div className="row border-privacy">
            <div className="col  border-privacy">
              <ul className="list-group privacy-list">
                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h1 className=" text-white under-line">
                      Effective Date: [10/04/2023]
                      {/* {t('aot')} */}
                    </h1>
                  </div>
                  <p className="fs-6">
                    This Privacy Policy outlines how Lampadina ("we," "us," or
                    "our") collects, uses, discloses, and protects the personal
                    information of users ("you" or "users") who interact with
                    our services, including our chat robot. We are committed to
                    safeguarding your privacy and ensuring the security of your
                    personal information. By using our services, you consent to
                    the terms and practices described in this Privacy Policy.
                    {/* {t('aot description')} */}
                  </p>
                </li>

                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      1. Information Collection
                      {/* {t("eligibility")} */}
                    </h3>
                  </div>
                  <p className="fs-6">
                    When you interact with our services, you may voluntarily
                    provide certain personal information, such as your name,
                    email address, contact information, or any other information
                    you choose to provide. We collect this information to
                    provide the requested services and communicate with you
                    effectively.
                    {/* {t('eligibility desc')} */}
                  </p>
                </li>

                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      1.2 Automatically Collected Information:
                      {/* {t('userconduct')} */}
                    </h3>
                  </div>
                  <p className="fs-6">
                    We automatically collect certain information when you access
                    or use our services. This information includes your IP
                    address, browser type, device information, usage data, and
                    other similar data. We collect this information through
                    cookies, log files, and similar technologies. The purpose of
                    collecting this information is to analyze trends, administer
                    the services, track users' movements, and gather demographic
                    information.
                    {/* {t("userconduct desc")} */}
                  </p>
                </li>
                <div>
                  <h1 className="text-white under-line">
                    {" "}
                    2. Use of Information
                  </h1>
                </div>
                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      2.1 Provide and Improve Services:
                      {/* {t('propertyrights')} */}
                    </h3>
                  </div>
                  <p className="fs-6 text-white">
                    We use the collected information to deliver and improve our
                    services, personalize your experience, and ensure the
                    functionality and performance of our services.
                    {/* {t('propertyrights desc')} */}
                  </p>
                </li>

                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      2.2 Communication:
                      {/* {t('usage')} */}
                    </h3>
                  </div>
                  <p className="fs-6 text-white">
                    We may use your contact information to respond to inquiries,
                    provide support, send important updates, and notify you of
                    any changes or modifications to our services.
                    {/* {t('usage desc')} */}
                  </p>
                </li>

                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      2.3 Research and Analytics:{/* {t('limitations')} */}
                    </h3>
                  </div>
                  <p className="fs-6 text-white">
                    We may analyze the collected data to gain insights, perform
                    research, and improve the quality of our services. This may
                    involve aggregating and anonymizing data for statistical
                    purposes.
                    {/* {t('limitations desc')} */}
                  </p>
                </li>
                <div>
                  <h1 className="text-white under-line ">
                    3. Information Sharing
                  </h1>
                </div>

                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      3.1 Third-Party Service Providers:
                      {/* {t('indemnification')} */}
                    </h3>
                  </div>
                  <p className="fs-6 text-white">
                    We may engage trusted third-party service providers to
                    assist us in operating our services, perform tasks on our
                    behalf, and enhance our offerings. These providers have
                    access to your personal information only to the extent
                    necessary to fulfill their contractual obligations and are
                    obligated to maintain its confidentiality.
                    {/* {t('indemnification desc')} */}
                  </p>
                </li>
                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      3.2 Legal Compliance:
                      {/* {t('indemnification')} */}
                    </h3>
                  </div>
                  <p className="fs-6 text-white">
                    We may disclose your personal information if required by
                    law, regulation, legal process, or governmental request. We
                    may also disclose your information to enforce our rights,
                    protect the security or integrity of our services, or
                    investigate potential violations.
                    {/* {t('indemnification desc')} */}
                  </p>
                </li>
                <div>
                  <h1 className="text-white under-line">4. Data Ownership and Usage</h1>
                </div>
                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      4.1 Data Ownership:
                      {/* {t('indemnification')} */}
                    </h3>
                  </div>
                  <p className="fs-6 text-white">
                    You acknowledge and agree that we have full access to the
                    data collected through our services and retain ownership of
                    such data.
                    {/* {t('indemnification desc')} */}
                  </p>
                </li>
                <li className="list-group-item privacy-list-items">
                  <div className="privacy-heading">
                    <h3 className="">
                      4.2 Data Usage:
                      {/* {t('indemnification')} */}
                    </h3>
                  </div>
                  <p className="fs-6 text-white">
                    We may use the collected data, including personal
                    information, for our legitimate business purposes, including
                    but not limited to internal analytics, research, development
                    of new features, marketing, and promotional activities. We
                    may also sell or transfer this data to third parties for
                    commercial purposes, provided it is done in accordance with
                    applicable laws and regulations.
                    {/* {t('indemnification desc')} */}
                  </p>
                </li>
                <div>
                  <h1 className="text-white ">5. Data Security</h1>
                  <p className="text-white fs-6">
                    We implement reasonable security measures to protect your
                    personal information from unauthorized access, disclosure,
                    alteration, or destruction. However, no method of
                    transmission over the internet or electronic storage is 100%
                    secure, and we cannot guarantee absolute security. You
                    acknowledge and accept this inherent risk when providing
                    your personal information.
                  </p>
                </div>
                <div>
                  <h1 className="text-white  ">6. Third-Party Links</h1>
                  <p className="fs-6 text-white">
                    Our services may contain links to third-party websites or
                    services. We are not responsible for the privacy practices
                    or content of these third parties. We encourage you to
                    review the privacy policies of those third parties before
                    providing any personal information.
                  </p>
                </div>
                <div>
                  <h1 className="text-white ">7. Children's Privacy</h1>
                  <p className="text-white fs-6">
                    Our services are not intended for individuals under the age
                    of 13. We do not knowingly collect personal information from
                    children. If we become aware that we have inadvertently
                    collected personal information from a child, we will take
                    steps to delete it promptly. If you believe that we may have
                    collected information from a child, please contact us using
                    the
                  </p>
                </div>
                <div>
                  <h1 className="text-white text-center under-line2 ">
                    information provided below.
                  </h1>
                </div>
                <div>
                  <h1 className="text-white ">
                    8. Changes to the Privacy Policy
                  </h1>
                  <p className="text-white fs-6">
                    We reserve the right to modify or update this Privacy Policy
                    at any time. We will provide notice of any material changes
                    by posting the updated policy on our website. Your continued
                    use of our services after the effective date of the revised
                    Privacy Policy constitutes your acceptance of the updated
                    terms.
                  </p>
                </div>
                <div>
                  <h1 className="text-white ">9. Contact Us</h1>
                  <p className="text-white fs-6">
                    If you have any questions, concerns, or requests regarding
                    this Privacy Policy or our privacy practices, please contact
                    us at [insert contact information].
                  </p>
                  <p className="fs-6 text-white">
                    Please note that this is a generalized privacy policy
                    template and may not fully address your specific legal
                    requirements. It is essential to consult with a legal
                    professional to ensure compliance with applicable privacy
                    laws and regulations and tailor the privacy policy to your
                    specific circumstances.
                  </p>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
