import React from "react";
import AppHeader from "../@components/AppHeader";

const UserAccountPage = () => {
  return (
    <div>
      <AppHeader />
      <section className="user-account">
        <div className="user-account-heading">
          <h1 className="text-white ms-3 mt-3">User Account</h1>
        </div>

        <div className="user-account-description"></div>
      </section>
    </div>
  );
};
export default UserAccountPage;
