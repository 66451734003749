import React from "react";
import AppHeader from "../@components/AppHeader";

const ContactUsPage = () => {
  return (
    <div>
      <AppHeader />
      <section className="contact-us">
        <div className="contact-us-heading">
          <h1 className="text-white ms-3 mt-3">Contact us</h1>
        </div>

        <div className="contact-description"></div>
      </section>
    </div>
  );
};
export default ContactUsPage;
