import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../@api/auth";
import { useDispatch, useSelector } from "react-redux";
import { login, selectUser, rememberMe } from "../auth/AuthSlice";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import AppHeader from "../@components/AppHeader";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

type UserLoginForm = {
  username: string;
  password: string;
};

const LoginPage = () => {
  const userCredentials = useSelector((state: any) => state.user);
  const [remember, setRemember] = useState(false);
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UserLoginForm>({
    resolver: yupResolver(validationSchema),
  });
  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRemember(e.target.checked);
  };
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const user = useSelector(selectUser);

  const dispatch = useDispatch();

  const onSubmit = async (data: UserLoginForm, e: any) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("username", data.username);
      formData.append("password", data.password);

      const resp = await loginApi(formData);
      if (resp.data.verified === true) {
        dispatch(login({ user: resp?.data }));
        // dispatch(login({ user:{...user, Status : "Paid"} }));
        if (remember) {
          dispatch(
            rememberMe({
              username: data.username,
              password: data.password,
            })
          );
        }
        navigate("/chat-bot");
        toast.success("Login Successfully", {
          iconTheme: { primary: "#ef09a1de", secondary: "" },
        });
      } else {
        navigate("/verify");
        toast.error("Please verify your account.");
      }
    } catch (error: any) {
      console.log(error);
      toast.error(error.response?.data.detail);
    }
  };

  useEffect(() => {
    if (userCredentials.username) {
      // If there are stored credentials, pre-fill the form fields
      setValue("username", userCredentials.username);
      setValue("password", userCredentials.password || "");
      // setRemember(true);
    }
    // else {
    //   setValue("username", "");
    //   setValue("password", "");
    // }
  }, [setValue]);

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <div>
      <AppHeader />
      <section className="login-page">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-heading ">
            <h1 className="ms-3 mt-3">{t("Login")}</h1>
          </div>
          <div className="login-detail d-flex align-items-center justify-conten-center">
            <div className="w-100 d-flex justify-content-center">
              <div className="log-board d-flex flex-column  justify-content-between w-50 p-lg-5 p-md-5 p-sm-5 p-4">
                <div className="profile-pic d-flex justify-content-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="512"
                    height="512"
                    x="0"
                    y="0"
                    viewBox="0 0 53 53"
                    className=""
                  >
                    <g>
                      <path
                        d="m18.613 41.552-7.907 4.313a7.106 7.106 0 0 0-1.269.903A26.377 26.377 0 0 0 26.5 53c6.454 0 12.367-2.31 16.964-6.144a7.015 7.015 0 0 0-1.394-.934l-8.467-4.233a3.229 3.229 0 0 1-1.785-2.888v-3.322c.238-.271.51-.619.801-1.03a19.482 19.482 0 0 0 2.632-5.304c1.086-.335 1.886-1.338 1.886-2.53v-3.546c0-.78-.347-1.477-.886-1.965v-5.126s1.053-7.977-9.75-7.977-9.75 7.977-9.75 7.977v5.126a2.644 2.644 0 0 0-.886 1.965v3.546c0 .934.491 1.756 1.226 2.231.886 3.857 3.206 6.633 3.206 6.633v3.24a3.232 3.232 0 0 1-1.684 2.833z"
                        fill="#e7eced"
                        data-original="#e7eced"
                      ></path>
                      <path
                        d="M26.953.004C12.32-.246.254 11.414.004 26.047-.138 34.344 3.56 41.801 9.448 46.76a7.041 7.041 0 0 1 1.257-.894l7.907-4.313a3.23 3.23 0 0 0 1.683-2.835v-3.24s-2.321-2.776-3.206-6.633a2.66 2.66 0 0 1-1.226-2.231v-3.546c0-.78.347-1.477.886-1.965v-5.126S15.696 8 26.499 8s9.75 7.977 9.75 7.977v5.126c.54.488.886 1.185.886 1.965v3.546c0 1.192-.8 2.195-1.886 2.53a19.482 19.482 0 0 1-2.632 5.304c-.291.411-.563.759-.801 1.03V38.8c0 1.223.691 2.342 1.785 2.888l8.467 4.233a7.05 7.05 0 0 1 1.39.932c5.71-4.762 9.399-11.882 9.536-19.9C53.246 12.32 41.587.254 26.953.004z"
                        fill="#556080"
                        data-original="#556080"
                        className=""
                      ></path>
                    </g>
                  </svg>
                </div>

                <div className="input-group my-3 mb-4">
                  <span
                    className="input-group-text  d-flex justify-content-center align-items-center"
                    id="basic-addon1"
                  >
                    <i className="fa-regular fa-user"></i>
                  </span>
                  <input
                    type="text"
                    // className="form-control user-name"
                    placeholder={t("username") ?? ""}
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    // value={user}
                    {...register("username")}
                    className={`form-control position-relative user-name ${
                      errors.username ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback position-absolute">
                    {errors.username?.message}
                  </div>
                </div>
                <div className="input-group mb-3">
                  <span
                    className="input-group-text d-flex justify-content-center align-items-center"
                    id="basic-addon1"
                  >
                    <i className="fa-solid fa-lock"></i>
                  </span>
                  <input
                    type="password"
                    // className="form-control password"
                    placeholder={t("password") ?? ""}
                    aria-label="Password"
                    aria-describedby="basic-addon1"
                    // onChange={(e) => setPwd(e.target.value)}
                    // value={pwd}
                    {...register("password")}
                    className={`form-control position-relative password ${
                      errors.password ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback position-absolute">
                    {errors.password?.message}
                  </div>
                </div>

                <div className="forgot-password d-flex justify-content-between px-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      checked={remember}
                      onChange={handleRememberMeChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      {t("remember me")}
                    </label>
                  </div>
                  <a href="/forgot-password">{t("forgot password")}</a>
                </div>
                <div className="login-btn d-flex justify-content-center mt-3">
                  <button
                    type="submit"
                    className="btn  w-75 rounded-pill"
                    // onClick={handleSubmit}
                    onKeyDown={handleKeyPress}
                  >
                    {t("login btn")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};
export default LoginPage;
