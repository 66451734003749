import React, { useState } from "react";
import AppHeader from "../@components/AppHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { initiateTrial, login, selectUser } from "../auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticatePayment,
  createCheckoutSession,
  trialApi,
} from "../@api/auth";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";

const SubscriptionPage = () => {
  // const [response,setResponse] = useState({})
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("Tigrinya");
  const user = useSelector(selectUser);
  const authToken = user.token;
  const [payment, setPayment] = useState('');
  const [plan, setPlan] = useState("");
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = !!user.token;

  const [show, setShow] = useState(false);
  const [username, setUsername] = useState<string>("");

  const handleUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value); // Update the state with the new email value
  };

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleShow = () => {
    if (authToken) {
      setShow(true); // Show the modal
    } else {
      toast.error("Please login First");
      navigate("/login"); // Navigate to the login page
    }
  };

  const handleFreeTrial = async () => {
    if (!authToken) {
      toast.error("Please log in first");
      navigate("/login"); // Navigate to the login page
      return;
    }

    trialApi(authToken)
      .then((response) => {
        toast.success(response.data.Detail);
        dispatch(initiateTrial({ trial: "y" }));
        navigate("/chat-bot");
      })
      .catch((error) => {
        toast.error(error.response?.data.detail);
        console.log(error.response?.data.detail);
      });
  };

  // const handleChange = async (event: any) => {
  // Listen for changes in the CardElement
  // and display any errors as the customer types their card details
  //   setDisabled(event.empty);
  //   setError(event.error ? event.error.message : "");
  // };

  // const handlePayment = (amount, plan) => {
  //   setPayment(amount);
  //   setPlan(plan);
  // };

  const translatedSave = t("save");
  const percentage = "10%";
  const percentage1 = "30%";

  const payMoney = async (payment:number) => {
    // e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const sessionData = {
      payment:payment,
    };

    console.log(payment)

    try {
      const response = await createCheckoutSession(sessionData);
      window.open(response.data, '_blank');
      
      // const clientSecret = response.data.clientSecret;
      // const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      //   payment_method: {
      //     card: elements.getElement(CardElement)!,
      //     billing_details: {
      //       name: username,
      //     },
      //   },
      // });
      // setPaymentLoading(false);
      // if (paymentResult.paymentIntent?.status === "succeeded") {
      //   if (selectedLanguage || (payment !== 29.99 && payment !== 49.99)) {
      //     // Call the add_payment endpoint with the stripe ID and selected language
      //     let language;
      //     let key;
      //     if (payment === 19.99) {
      //       language = selectedLanguage;
      //       key = 1;
      //     } else if (payment === 29.99) {
      //       language = "any";
      //       key = 2;
      //     } else {
      //       language = "any";
      //       key = 3;
      //     }

      //     const paymentData = {
      //       stipeId: paymentResult.paymentIntent.id,
      //       language: language,
      //       key: key,
      //     };
      //     const response = await authenticatePayment(paymentData);

          toast.success(response.data.Detail, {
            iconTheme: { primary: "#ef09a1de", secondary: "" },
          });
          dispatch(login({ user: { ...user, Status: "Paid" } }));
          navigate("/chat-bot");
        }
      // } else {
      //   toast.error("Payment not succeeded. Please try again.");
      //   handleClose();
      // }
  // }
     catch (error) {
      console.log(error);
      setPaymentLoading(false);
      toast.error("Payment failed. Please try again.");
    }
  };
  return (
    <div>
      <AppHeader />

      {/* <Modal show={show} onHide={handleClose} centered> */}
        {/* <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body>
          <form className="w-100" onSubmit={payMoney}>
            <div className="modal-body">
              <div className="d-flex flex-row w-100 payment-title mb-1 login-page input">
                <label>Enter Username</label>
              </div>
              <div className="subscription-field">
                <input
                  type="text"
                  // className="form-control user-name"
                  placeholder="Enter Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleUserChange}
                  value={username}
                  // {...register("username")}
                  className="form-control subscription-input bg-transparent text-white"
                />
              </div>
              <div className="d-flex flex-row w-100 payment-title mb-1">
                <label>Credit or debit card</label>
              </div>
             <CardElement
                options={{
                  style: {
                    base: {
                      color: "#fff",
                      iconColor: "white",
                      fontSmoothing: "antialiased",
                      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
                      fontWeight: "500",
                    },
                  },
                }}
              /> 
              {payment === 19.99 && (
                <>
                  <div className="d-flex flex-row w-100 payment-title mt-3">
                    <label>Please Select Language</label>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="form-check me-2">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        value="Tigrinya"
                        onClick={() => setSelectedLanguage("Tigrinya")}
                        required
                      />
                      <label
                        className="form-check-label payment-title"
                        htmlFor="flexRadioDefault1"
                      >
                        Tigrinya
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        value="Amharic"
                        onClick={() => setSelectedLanguage("Amharic")}
                        required
                      />
                      <label
                        className="form-check-label payment-title"
                        htmlFor="flexRadioDefault2"
                      >
                        Amharic
                      </label>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="modal-footer payment-btn">
              <button className="btn w-100" disabled={isPaymentLoading}>
                <span>
                  {isPaymentLoading ? "Loading..." : "Submit Payment"}
                </span>
              </button>
            </div>
          </form>
        </Modal.Body> */}
      {/* </Modal> */}
      <section className="subscription">
        <div className="subscription-heading">
          <h1 className="text-white my-3 ps-3">{t("subscription")}</h1>
        </div>

        <div className="container-fluid mt-5">
          <div className="row justify-content-center">
            {/* {user.trial !== "y" && ( */}
            {/* <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="pricing-card basic mb-2">
                <div className="pricing-header">
                  <span className="plan-title">{t("ft")}</span>
                  <div className="price-circle">
                    <span className="price-title">
                      <small>$</small>
                      <span>0</span>
                    </span>
                    <div className="info ">
                      <p className=" mb-0 ">150 {t("words")}</p>
                      <span className="">
                        {" "}
                        <br /> {t("oneuser")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="badge-box">
                  <div></div>
                </div>
                <ul className="plan-list">
                  <li>{t("chatgptbot")}</li>
                  <li>{t("tigoramharic")}</li>
                  <li>{t("sfa")}</li>
                  <li>{t("useraccount")}</li>
                </ul>
                <div className="buy-button-box">
                  <button
                    className="btn free-trial-btn"
                    onClick={handleFreeTrial}
                    disabled={user.trial !== "y" && isLoggedIn}
                  >
                    {t("buy")}
                  </button>
                </div>
              </div>
            </div> */}
            {/* )} */}
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="pricing-card basic mb-2">
                <div className="pricing-header">
                  <span className="plan-title">{t("bp")}</span>
                  <div className="price-circle">
                    <span className="price-title">
                      <small>$</small>
                      <span>19.99</span>
                    </span>
                    <div className="info ">
                      <p className=" mb-0 ">10,000 {t("words")}</p>
                      <span className="">
                        {" "}
                        <br /> {t("oneuser")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="badge-box">
                  <div></div>
                </div>
                <ul className="plan-list">
                  <li>{t("chatgptbot")}</li>
                  <li>{t("tigoramharic")}</li>
                  <li>{t("sfa")}</li>
                  <li>{t("useraccount")}</li>
                </ul>
                <div
                  className="buy-button-box"
                  // onClick={() => setPayment('price_1OfHAFK9NxU9GGw7Z2jq3Bdi')}
                >
                  <a className="buy-now" onClick={()=>{
                    // setPayment('price_1OfHAFK9NxU9GGw7Z2jq3Bdi')
                    payMoney(1)
                  }}>
                    {t("buy")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="pricing-card pro mb-2">
                <div className="popular">{t("popular")}</div>
                <div className="pricing-header">
                  <span className="plan-title">{t("pp")}</span>
                  <div className="price-circle">
                    <span className="price-title">
                      <small>$</small>
                      <span>29.99</span>
                    </span>
                    <div className="info ">
                      <p className=" mb-0 ">30,000 {t("words")}</p>
                      <span className="">
                        {" "}
                        <br /> {t("oneuser")}
                      </span>
                    </div>
                    {/* <span className="info mb-0">
                    <br /> 
                  </span> */}
                  </div>
                </div>
                <div className="badge-box">
                  <div>
                    {user.language === "ar" || user.language === "tr" ? (
                      <span>
                        {percentage} {translatedSave}
                      </span>
                    ) : (
                      <span>
                        {translatedSave} {percentage}
                      </span>
                    )}
                  </div>
                  {/* <span>{t("save")} 10%</span> */}
                </div>
                <ul className="overflow-auto plan-list">
                  <li>{t("chatgptbot")}</li>
                  <li>{t("ia")}</li>
                  <li>{t("it")}</li>
                  <li>{t("sfrq")}</li>
                  <li>{t("ecs")}</li>
                  <li>{t("ucp")}</li>
                  <li>{t("afbp")}</li>
                </ul>
                <div
                  className="buy-button-box"
                  // onClick={() => setPayment('price_1OfHBcK9NxU9GGw7JIrXUPqM')}
                >
                  <a className="buy-now" onClick={()=>{
                    // setPayment('price_1OfHAFK9NxU9GGw7Z2jq3Bdi')
                    payMoney(2)
                  }}>
                    {t("buy")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3">
              <div className="pricing-card business mb-2">
                <div className="pricing-header">
                  <span className="plan-title">{t("ep")}</span>
                  <div className="price-circle">
                    <span className="price-title">
                      <small>$</small>
                      <span>49.99</span>
                    </span>
                    <div className="info ">
                      <p className=" mb-0 ">Unlimited {t("words")}</p>
                      <span className="">
                        {" "}
                        <br /> {t("2u")}
                      </span>
                    </div>
                    {/* <span className="info">
                    {t("uw")} <br /> {t("2u")}
                  </span> */}
                  </div>
                </div>
                <div className="badge-box">
                  <div>
                    {user.language === "ar" || user.language === "tr" ? (
                      <span>
                        {percentage1} {translatedSave}
                      </span>
                    ) : (
                      <span>
                        {translatedSave} {percentage1}
                      </span>
                    )}
                  </div>
                  {/* <span>{t("save")} 30%</span> */}
                </div>
                <ul className="overflow-auto plan-list">
                  <li>{t("chatgptbot")}</li>
                  <li>{t("ia")}</li>
                  <li>{t("it")}</li>
                  <li>{t("fpge")}</li>
                  <li>{t("ubaw")}</li>

                  <li>{t("waso")}</li>
                  <li>{t("fssw")}</li>
                  <li>{t("sfrq")}</li>
                  <li>{t("dcs")}</li>
                  <li>{t("cotvp")}</li>
                  <li>{t("ucp")}</li>
                  <li>{t("fadecp")}</li>
                  <li>{t("afbpp")}</li>
                </ul>
                <div
                  className="buy-button-box"
                  // onClick={() => setPayment('price_1OfHC8K9NxU9GGw7neqMMdmd')}
                >
                  <a className="buy-now" onClick={()=>{
                    // setPayment('price_1OfHAFK9NxU9GGw7Z2jq3Bdi')
                    payMoney(3)
                  }}>
                    {" "}
                    {t("buy")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default SubscriptionPage;
