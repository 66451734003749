import { useEffect, useRef, useState } from "react";
import { forgotPassword } from "../@api/auth";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>("");
  // State to store the timer duration in seconds
  const [timerDuration, setTimerDuration] = useState(30);
  // State to track whether the button is disabled
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const intervalRef = useRef<number>();

  const [hover, setHover] = useState(false);

  const startTimer = () => {
    setIsButtonDisabled(true);

    // Use the intervalRef to hold the interval ID
    intervalRef.current = window.setInterval(() => {
      setTimerDuration((prevDuration) => Math.max(prevDuration - 1, 0));
    }, 1000);

    // Clear the interval when the timer expires
    setTimeout(() => {
      clearInterval(intervalRef.current);
    }, 30000);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value); // Update the state with the new email value
  };

  const handleResendEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Call the API function 'forgotPassword' and pass the 'email' state as data
      if (!email) {
        return;
      }

      setIsButtonDisabled(true);
      startTimer();

      const response = await forgotPassword({ email });
      if (response?.data?.message) {
        toast.success(response.data.message, {
          iconTheme: { primary: "#ef09a1de", secondary: "" },
        });
      } else {
        toast.error(response.data.message);
      }
      // Handle the response as needed
    } catch (error) {
      // Handle errors if the API call fails
      console.error(error);
    }
  };

  useEffect(() => {
    if (timerDuration == 0) {
      setIsButtonDisabled(false);
      setTimerDuration(30);
      setHover(false);
    }
  }, [timerDuration]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleResendEmail(e);
    }
  };

  return (
    <div>
      <section className="login-page">
        {/* <div className="upcoming-events-heading">
          <h1 className="text-white ms-3 mt-3">Upcoming Events</h1>
        </div> */}

        <div className="verify-box d-flex align-items-center h-100">
          <div className="container">
            <div className="row  justify-content-center">
              <div className="col-md-6">
                <div className="card h-100 w-100">
                  <form onSubmit={handleResendEmail}>
                    {/* <img src={robot3} className="" alt="..." /> */}
                    <div className="card-body border-radius-0 text-center p-5">
                      <h6 className="text-white fw-bold">Forgot Password</h6>
                      <p className="text-white">
                        Enter Your Email, We’ll send password Reset link to your
                        Email
                      </p>
                      <div className="input-group mb-3">
                        <span
                          className="input-group-text d-flex justify-content-center align-items-center"
                          id="basic-addon1"
                        >
                          <i className="fa-solid fa-lock"></i>
                        </span>
                        <input
                          type="email"
                          // className="form-control password"
                          placeholder="Enter Email"
                          aria-label="Password"
                          aria-describedby="basic-addon1"
                          onChange={handleEmailChange}
                          onKeyDown={handleKeyDown}
                          value={email}
                          // {...register("password")}
                          className={`form-control position-relative password`}
                        />
                      </div>
                      <button
                        className="verify-btn w-75"
                        onClick={handleResendEmail}
                        disabled={isButtonDisabled}
                        type="button"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() =>
                          !isButtonDisabled && setHover(false)
                        }
                        style={{
                          backgroundColor: hover ? "#db1d9c" : "#ef09a1",
                          cursor: hover ? "pointer" : "",
                        }}
                      >
                        {isButtonDisabled
                          ? `Resend in ${timerDuration}s`
                          : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
