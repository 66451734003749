import { signUpApi } from "../@api/auth/index";
import * as Yup from "yup";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import { useNavigate } from "react-router-dom";
import AppHeader from "../@components/AppHeader";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import "../style/Signup.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type UserSubmitForm = {
  firstname: string;
  lastname: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  day: Date;
  month: Date;
  year: Date;
  dob: Date;
};

const SignUpPage = () => {
  const [show, setShow] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Firstname is required"),
    lastname: Yup.string().required("Lastname is required"),
    email: Yup.string().required("Email is required").email("Invalid email format"),
    confirmEmail: Yup.string()
      .required("Confirm Email is required")
      .oneOf([Yup.ref("email"), ""], "Confirm Email does not match"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters")
      .matches(
        /^(?=.*[!@#$%^&*])/,
        "Password must contain at least one special character (!@#$%^&*)"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), ""], "Confirm Password does not match"),
      dob: Yup.date()
    .required("Date is required")
    .max(new Date(), "Date cannot be greater than today"),
    // .matches(
    //   /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-\d{4}$/,
    //   "Date of Birth must be a valid date in the format DD-MM-YYYY"
    // ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  });
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    setShow(false);
    setIsModalClosed(true);
  };

  const handleShow = () => {
    setShow(true); // Show the modal
  };

  const handleCheckboxChange = (event: any) => {
    setShow(false);
    setIsCheckboxChecked(false);
  };

  const onSubmit = async (data: UserSubmitForm, e: any) => {
    e.preventDefault();
    const date = (new Date(data.dob).getDate()).toString().padStart(2, '0') + '/' + ((new Date(data.dob).getMonth()) + 1).toString().padStart(2, '0') + '/' + new Date(data.dob).getFullYear();


    try {
      const response = await signUpApi({
        email: data.email,
        password: data.password,
        firstName: data.firstname,
        lastName: data.lastname,
        // DoB: `${data.day}-${data.month}-${data.year}`,
        DoB: date,
      });

      if (response.status === 200) {
        localStorage.setItem("email", data.email);
        navigate("/verify");
      } else {
        console.log(response.data.error); // handle error messages
      }
    } catch (error: any) {
      const errorMessage = JSON.parse(error.request.response).detail;
      toast.error(errorMessage);
    }
  };

  let navigate = useNavigate();
  return (
    <div>
      <AppHeader />
      <section className="sign-up">
        <div className="sign-up-heading">
          <h1 className="text-white ms-3 mt-3">{t("Sign up")}</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="signup-detail d-flex align-items-center justify-conten-center">
            <div className="signup-box w-100 d-flex justify-content-center">
              <div className="signup-board d-flex flex-column  justify-content-between  p-lg-5 p-md-5 p-sm-5 p-2">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column w-50">
                    <input
                      type="text"
                      // className="form-control fst-name  "
                      placeholder={t("First name") ?? ""}
                      aria-label="Example text with button addon"
                      aria-describedby="button-addon1"
                      {...register("firstname")}
                      className={`form-control fst-name ${
                        errors.firstname ? "is-invalid" : ""
                      }`}
                    />

                    <div className="invalid-feedback">
                      {errors.firstname?.message}
                    </div>
                  </div>
                  <div className="d-flex flex-column w-50">
                    <input
                      type="text"
                      // className="form-control last-name "
                      placeholder={t("Last name") ?? ""}
                      aria-label="Example text with button addon"
                      aria-describedby="button-addon1"
                      {...register("lastname")}
                      className={`form-control last-name ${
                        errors.lastname ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.lastname?.message}
                    </div>
                  </div>
                </div>
                <input
                  type="email"
                  // className="form-control email  mx-auto"
                  placeholder={t("Email") ?? ""}
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  {...register("email")}
                  className={`form-control email mx-auto ${
                    errors.email ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
                <input
                  type="email"
                  // className="form-control email mx-auto"
                  placeholder={t("Confirm email") ?? ""}
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  {...register("confirmEmail")}
                  className={`form-control email ${
                    errors.confirmEmail ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.confirmEmail?.message}
                </div>
                <input
                  type="password"
                  // className="form-control password   mx-auto"
                  placeholder={t("Password") ?? ""}
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  {...register("password")}
                  className={`form-control password ${
                    errors.password ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.password?.message}
                </div>
                <input
                  type="password"
                  // className="form-control password   mx-auto"
                  placeholder={t("Confirm password") ?? ""}
                  aria-label="Example text with button addon"
                  aria-describedby="button-addon1"
                  {...register("confirmPassword")}
                  className={`form-control password ${
                    errors.confirmPassword ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.confirmPassword?.message}
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <input
                    type="date"
                    {...register("dob")}
                    className={`form-control year text-white w-50 ${
                      errors.dob ? "is-invalid" : ""
                    }`}
                  />
                </div>
                <div className="invalid-feedback">{errors.dob?.message}</div>

                <p className="agree mb-0 under-line1" onClick={handleShow}>
                  <a className="under-line1 clicking-terms">
                    {t("Privacy policy")}{" "}
                  </a>
                </p>
                <Modal size="lg" show={show} onHide={handleClose} centered>
                  <Modal.Dialog className="modal-width">
                    <Modal.Header className="modal-header1">
                      <div className="terms-condition-heading">
                        <h1
                          className="text-white ms-3 mt-3"
                          style={{ fontSize: "2rem" }}
                        >
                          Terms & Services
                        </h1>
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      <section className="terms-condition">
                        <div className="terms-condition-description">
                          <div className="container">
                            <div className="row border-term">
                              <div className="col-12">
                                <ul className="list-group modal-term-list overflow-auto">
                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">Acceptance of Terms</h3>
                                    </div>
                                    <p>
                                      By using our AI chatbot site, you agree to
                                      be bound by these Terms and Conditions. If
                                      you do not agree with any part of these
                                      terms, you must not use our services.
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">Eligibility</h3>
                                    </div>
                                    <p>
                                      To access and use our AI chatbot site, you
                                      must be at least 13 years old or the age
                                      of majority in your jurisdiction,
                                      whichever is higher. By using our site,
                                      you represent that you meet this age
                                      requirement and comply with all applicable
                                      local, national, and international laws
                                      and regulations.
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">User Conduct</h3>
                                    </div>
                                    <p>
                                      You agree not to use our AI chatbot site
                                      for any unlawful purpose or to engage in
                                      any conduct that may harm, interfere with,
                                      or disrupt the site or its users. You are
                                      solely responsible for your interactions
                                      with the chatbot and any content you
                                      provide.
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">
                                        Intellectual Property Rights
                                      </h3>
                                    </div>
                                    <p>
                                      All content on our AI chatbot site,
                                      including but not limited to text,
                                      graphics, logos, and software, is the
                                      property of the site or its licensors and
                                      is protected by applicable copyright,
                                      trademark, and other intellectual property
                                      laws. You agree not to reproduce,
                                      distribute, or create derivative works
                                      based on our content without our prior
                                      written consent.
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">
                                        API Usage and Information Control
                                      </h3>
                                    </div>
                                    <p>
                                      Our AI chatbot relies on third-party APIs
                                      for its functionality. We do not control
                                      or endorse the information provided by
                                      these APIs, and we are not responsible for
                                      any errors, inaccuracies, or omissions in
                                      the content they provide.
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">
                                        Limitation of Liability
                                      </h3>
                                    </div>
                                    <p>
                                      While we strive to provide accurate
                                      translations and information, our AI
                                      chatbot may occasionally produce errors or
                                      incorrect translations. We do not
                                      guarantee the accuracy, completeness, or
                                      reliability of the translations and
                                      information provided by our chatbot. By
                                      using our chatbot, you acknowledge and
                                      agree that we shall not be liable for any
                                      damages or losses resulting from
                                      inaccuracies or errors in the content
                                      provided.
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">Indemnification</h3>
                                    </div>
                                    <p>
                                      You agree to indemnify and hold us
                                      harmless from any claims, damages, losses,
                                      or expenses (including reasonable
                                      attorneys' fees) arising from your
                                      violation of these terms, your use of our
                                      AI chatbot site, or any content you submit
                                      or provide through the site.
                                    </p>
                                  </li>
                                  <div className="Subscription-Term-heading">
                                    <h1 className="">Subscription Terms</h1>
                                  </div>
                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">
                                        Subscription Duration
                                      </h3>
                                    </div>
                                    <p>
                                      All subscriptions are valid for a period
                                      of one (1) year from the date of purchase,
                                      unless otherwise specified. Subscriptions
                                      will automatically renew for an additional
                                      year unless canceled by the subscriber as
                                      per the Renewal and Termination provisions
                                      stated below.
                                    </p>
                                  </li>
                                  <div className="Subscription-Term-heading">
                                    <h1 className="">
                                      Renewal and Termination
                                    </h1>
                                  </div>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">Automatic Renewal</h3>
                                    </div>
                                    <p>
                                      Subscriptions will automatically renew for
                                      an additional one (1) year term at the end
                                      of the initial subscription period, unless
                                      the subscriber cancels the subscription in
                                      accordance with the cancellation procedure
                                      outlined below.
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">
                                        Cancellation Procedure
                                      </h3>
                                    </div>
                                    <p>
                                      To cancel a subscription, subscribers must
                                      notify us in writing at least thirty (30)
                                      days before the end of their current
                                      subscription period. Upon receiving a
                                      valid cancellation request, we will not
                                      renew the subscription, and it will expire
                                      at the end of the current term.
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">Refund Policy</h3>
                                    </div>
                                    <p>
                                      We do not offer refunds for any
                                      subscription fees already paid. However,
                                      subscribers may continue to access our
                                      services until the end of their current
                                      subscription period, even if they have
                                      requested a cancellation
                                    </p>
                                  </li>

                                  <li className="list-group-item term-list-items">
                                    <div className="term-heading">
                                      <h3 className="">
                                        Modifications to the Service
                                      </h3>
                                    </div>
                                    <p>
                                      We reserve the right to modify or
                                      discontinue our AI chatbot site and its
                                      features at any time without prior notice.
                                      We will not be liable for any
                                      modification, suspension, or
                                      discontinuation of the site or any part
                                    </p>
                                  </li>
                                </ul>

                                {/* <ul className="list-group subscription-term-list">
                                  
                                </ul> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="d-flex align-items-center flex-row justify-content-start  w-100">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label text-white"
                            htmlFor="flexCheckDefault"
                          >
                            I have read and agreed with the terms and
                            conditions.
                          </label>
                        </div>
                      </div>
                    </Modal.Footer>
                  </Modal.Dialog>
                </Modal>
                <div className="signup-btn d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isCheckboxChecked}
                  >
                    <span>{t("Create account")}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};
export default SignUpPage;
