import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import lamdinaicon from "../assets/images/icon_Lampadina1.jpg";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { selectUser, logout } from "../auth/AuthSlice";
import SyntaxHighlighter from 'react-syntax-highlighter';
import atelierCaveDark from "react-syntax-highlighter/dist/esm/styles/hljs/atelier-cave-dark";
import Typist from 'react-typist-component';
import Modal from "react-bootstrap/Modal";
import {
  deleteChatHistory,
  getChatApi,
  logoutApi,
  updateChatApi,
  unsubsribe
} from "../@api/auth";
import { toast } from "react-hot-toast";
import AppHeader from "../@components/AppHeader";
import { useTranslation } from "react-i18next";
import Typewriter from "typewriter-effect";
import CodeBlockWithCopy from "../@components/copyCode";
import { docco, dracula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import AutoScrollContainer from "../@components/AutoScroll";

export default function ChatBotPage() {

  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectMode, setSelectedMode] = useState("dark");
  const [chatData, setChatData] = useState<any>({});
  const [newMessage, setNewMessage] = useState("");
  const [continueChatId, setContinueChatId] = useState("");
  const user: any = useSelector(selectUser);
  const authToken = user.token;
  const firstName = user.firstName;
  const dispatch = useDispatch();
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [inputToggle, setInputToggle] = useState(true)
  const [firstTimeRendered, setFirstTimeRendered] = useState(false)
  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);
  const containerRef = React.useRef<null | HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [isFetchedMessage, setIsFetchedMessage] = useState(false);
  const [isFetchedMessageBullets, setIsFetchedMessageBullets] = useState(false);
  const [isFirstTypewriterComplete, setIsFirstTypewriterComplete] =
    useState(false);
  const [initScrolling, setInitScrolling] = useState(false);
  const [myInterval, setMyInterval] = React.useState<null | any>();
  const [showMessage, setShowMessage] = useState(false)
  const [parsedContentt, setParsedContent] = useState<null | any>();;
  const [splittedText, setSplittedText] = useState([]);
  const [lines, setLines] = useState([]);
  const [lineCompletionState, setLineCompletionState] = useState(
    new Array(3).fill(false) // Initially all lines incomplete
  );

  const scrollToBottom = () => {
    if (containerRef?.current)
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };

  // useEffect(() => {
  //   if (chatData && messagesEndRef.current) {
  //     scrollToBottom();
  //     inputRef.current?.focus();
  //   }
  // }, [JSON.stringify(chatData)]);

  const fetchChatApi = async () => {
    try {
      setIsLoader(true);
      const response = await getChatApi(authToken);
      const status = await JSON.parse(response.data.conversation);
      setChatData(status);
      setContinueChatId(response?.data?.id);
      // scrollToBottom();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };
  let flag = true
  useEffect(() => {
    if (flag)
      fetchChatApi();
    flag = false
    // setFirstTimeRendered(true)
  }, []);

  // renderTextBeforeCode=>(){

  // }


  const handleChatUpdate = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();

    // setFirstTimeRendered(false)
    if (e.key === "Enter") {
      if (newMessage.trim().length > 0) {
        setInputToggle(false)
        setIsLoading(true);
        const data = { id: continueChatId, conversation: newMessage };
        try {
          let chatU: any = Object.assign({}, chatData);
          if (!chatU.Chat) {
            chatU.Chat = []; // Initialize chatU.Chat as an empty array if it is undefined
          }
          console.log(chatData)
          chatU.Chat = [...chatU.Chat, { content: newMessage, role: "user" }];
          setChatData(chatU);
          setNewMessage("");
          // scrollToBottom();
          console.log(chatData)

          const response = await updateChatApi(data);
          setChatData(JSON.parse(response.data.conversation));
          setIsFetchedMessage(true);
          setIsFetchedMessageBullets(true);
          setIsFirstTypewriterComplete(false);
          setInitScrolling(false);
          // scrollToBottom();
          parseContent(response)
        } catch (error: any) {
          if (error.response.status === 410) {
            toast.error(error.response?.data.detail);
            setTimeout(() => {
              navigate("/subscription");
            }, 1000);
          }
          // console.log(error)
          console.log({ error });
        }
        setIsInputDisabled(false);
        setIsLoading(false);
        // moveScrollToBottom();
      }
    }
  };
  const handleLogout = async () => {
    try {
      const response = await logoutApi();
      dispatch(logout());
      toast.success(response.data.message, {
        iconTheme: { primary: "#ef09a1de", secondary: "" },
      });
      navigate("/login");
    } catch (error: any) {
      toast.error(error);
    }
  };

  const handleDeletion = async () => {
    try {
      const response = await getChatApi(authToken);
      const chatId = response?.data?.id;
      await deleteChatHistory({ id: chatId });
      // Handle successful deletion

      // Update chatData by removing the deleted chat
      // const updatedChatData = chatData.filter((chat: any) => chat.id !== chatId);
      setChatData([]);
      toast.success("Chat Deleted Successfully", {
        iconTheme: { primary: "#ef09a1de", secondary: "" },
      });
    } catch (error) {
      console.log(error);
      // Handle any errors that occur during the API call
    }
  };

  const unsubsribeSubscription = async (flag: boolean) => {
    setShowMessage(!showMessage)
    try {
      if (flag) {
        const response = await unsubsribe()
        toast.success(response.data.message)
        navigate('/')
      }
    } catch (e) {

    }
  }

  useEffect(() => {
    if (initScrolling) {
      const interval = setInterval(() => {
        // scrollToBottom();
      }, 1000);
      setMyInterval(interval);
    } else {
      clearInterval(myInterval);
    }
  }, [initScrolling]);

  let introText: string[] = [];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleTypingDone = (index: any) => {
    setLineCompletionState(prev => {
      prev[index] = true;
      return prev;
    });
    // setCurrentIndex((index + 1));
  };
  useEffect(() => {
    if (currentIndex > 0) {
      // renderTypist()
    }
  }, [currentIndex])
  const parseContent = (content: any) => {
    let contentx = JSON.parse(content.data.conversation)
    console.log(contentx)
    let ct = contentx.Chat[contentx.Chat.length - 1].content;
    ct = ct.replace(/\n+/g, '\n');
    ct = ct.replace("1.", '\n');
    let lines = ct.split('```')
    setLineCompletionState(new Array(lines.length).fill(false))
    setLines(lines)
  };

  const parseContent2 = (content: any) => {
    console.log(content)
    // console.log("hello")
    // content = content.replace('1.', "")
    let lines = content.split(/(```[\s\S]+?```)/);
    lines = lines.filter((block: any) => block.trim() !== '')
    // console.log(lines, "LInes")
    let codeBlock = false;
    let codeLines: any = [];
    let chatsDats: any = []

    console.log(lines)
    chatsDats.push(lines.map((line: any, index: any) => {
      if (line.startsWith('```')) {

        // line.replace('```','')
        // if (codeBlock) {
        // End of code block, render SyntaxHighlighter
        // const code = codeLines.join('\n');
        // codeLines = [];
        // codeBlock = false;
        return (

          <>
            <SyntaxHighlighter style={selectMode === 'dark' ? dracula : docco} lineProps={{ className: 'code-line' }} >
              {line}
            </SyntaxHighlighter>
            {/* <CodeBlockWithCopy code={code}>

              </CodeBlockWithCopy> */}
          </>

        );

      } else {
        // if (codeBlock) {
        // Inside code block
        // codeLines.push(line);
        // return null;
        console.log(line)
        console.log(user)
        // line = line.replace("\n,"<br>")
        return <div key={index} style={{ whiteSpace: 'pre-line' }} className={user.language === "ar" || user.language === "tr" ? "ethiopic-font" : ""}>{line}</div>;

      }
    }
    ))


    return chatsDats;
  }

  const renderChatData = useMemo(() => {
    return chatData?.Chat?.map((chat: any, index: any) => {
      // console.log(chatData, "Chat Data in Memo")
      const text = chat.content;
      const splittedText = text;

      let tempBulletsStorage = "";
      let renderBulletMessages = false;

      const FooText = (val: any) => {
        console.log(lines)
        if ((lines.length == val.index + 1)) {
          setInputToggle(true)
        }
        return <Typist typingDelay={0}

          key={val.index}
        >
          <div className={user.language === "ar" || user.language === "tr" ? "ethiopic-font" : ""}>
            {lines[val.index] ? lines[val.index] : ""}</div>
          {lines[val.index + 1] ? <FooCode index={val.index + 1} /> : <></>}

        </Typist>
      };
      const FooCode = (val: any) => {
        console.log(val.index);
        console.log(typeof (val.index));
        if ((lines.length == 1 || lines.length == val.index + 1)) {
          setInputToggle(true)
        }
        return <Typist key={val.index} // Important for React's reconciliation
        // onTypingDone={() => handleTypingDone(index)}
        >
          {lines[val.index] ?
            <SyntaxHighlighter style={selectMode === 'dark' ? dracula : docco} language="javascript" lineProps={{ className: 'code-line' }}>
              {lines[val.index]}
            </SyntaxHighlighter> : ""}
          {lines[val.index + 1] ? <FooText index={val.index + 1} /> : <></>}
        </Typist>;
      };
      return (
        <div
          key={index}
          className={
            chat.role === "user"
              ? selectMode === "dark"
                ? "dark-question d-flex flex-row align-items-center mx-auto"
                : "light-question d-flex flex-row align-items-center mx-auto"
              : selectMode === "dark"
                ? "dark-answer d-flex flex-row align-items-start justify-content-center mx-auto mb-3"
                : "light-answer d-flex flex-row align-items-start justify-content-center mx-auto mb-3"
          }
        >
          <div className="w-100 d-flex flex-row">
            <div className="yugen-logo-q">
              <div
                className={
                  selectMode === "dark"
                    ? "username-dark d-flex justify-content-center align-items-center"
                    : "username-light d-flex justify-content-center align-items-center"
                }
              >
                {chat.role === "user" ? (
                  <span className="">
                    {user.firstName.charAt(0).toUpperCase()}
                  </span>
                ) : (
                  <img
                    src={lamdinaicon}
                    className="img-fluid m-1"
                    alt="Triangle"
                  />
                )}
              </div>
            </div>
            <p className="my-auto  ps-1 code-ans me-4" style={{width:'94%'}}>
              {chat.role === "user" && (
                <div className="pe-5">
                  {splittedText}
                </div>
              )}

              {chat.role === "system" &&
                index === chatData.Chat.length - 1 &&
                isFetchedMessage ?
                (
                  (
                    (<Typist typingDelay={0}
                      key={index} // Important for React's reconciliation
                    >
                      <div style={{ whiteSpace: 'pre-line' }} className={user.language === "ar" || user.language === "tr" ? "ethiopic-font" : ""}> {lines[0]}</div>
                      <FooCode index={1} />


                    </Typist>)
                  )
                ) : (
                  (chat.role === "system") && (
                    <>
                      {parseContent2(splittedText)}
                    </>
                  )
                )}

              {/* {renderListItems()} */}

              {renderBulletMessages &&
                isFirstTypewriterComplete && (
                  <Typewriter
                    onInit={(typewriter) => {
                      typewriter
                        .typeString(tempBulletsStorage + " ")
                        .deleteChars(1)
                        .callFunction(() => setInitScrolling(false))
                        .start();
                    }}
                    options={{ cursor: "", delay: 20 }}
                  />
                )}
            </p>
          </div>
        </div>
      );
    })
  }, [chatData, selectMode]);

  return (
    <div
      className=""
      style={{ background: selectMode == "dark" ? "" : "white" }}
    >
      <AppHeader handleDeletion={handleDeletion} />
      <div className="container-fluid chat-bot " id="chat-bot">
        <div className="d-flex flex-column justify-content-between h-100">
          {isLoader ? (
            <div className="loader"></div> // Replace with your loader component
          ) : (
            <div className="answers " ref={containerRef}>

              <AutoScrollContainer>
                {renderChatData}
              </AutoScrollContainer>
              <div ref={messagesEndRef} />
            </div>
          )}
          <section
            className={
              selectMode === "dark"
                ? "chat-bot-dark type  position-sticky bottom-0"
                : "chat-bot-light type  position-sticky bottom-0"
            }
          >
            <div className=" d-flex align-items-end w-100 h-100">
              <div className="row w-100">
                <div className="col-lg-2 col-md-3 col-sm-3  col-4">
                  <ul
                    className={
                      selectMode == "dark"
                        ? "list-group chat-bot-list"
                        : "list-group chat-bot-list-light"
                    }
                  >
                    <li
                      className={
                        "list-group-item chat-bot-list-items d-flex flex-row "
                      }
                      style={{ border: 0 }}
                    >
                      <span
                        className="me-2"
                        onClick={() => {
                          if (selectMode == "dark") {
                            setSelectedMode("light");
                          } else {
                            setSelectedMode("dark");
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M2 12C2 8.68629 4.68629 6 8 6H16C19.3137 6 22 8.68629 22 12C22 15.3137 19.3137 18 16 18H8C4.68629 18 2 15.3137 2 12Z"
                            fill="white"
                            className=""
                          />
                          <path
                            d="M12 12C12 14.2091 10.2091 16 8 16C5.79086 16 4 14.2091 4 12C4 9.79086 5.79086 8 8 8C10.2091 8 12 9.79086 12 12Z"
                            fill="black"
                            className="path2"
                          />
                        </svg>
                      </span>
                      <p className="mb-0 my-auto">
                        {selectMode === "dark"
                          ? t("light mode")
                          : t("dark mode")}
                      </p>
                    </li>
                    <li
                      className="list-group-item chat-bot-list-items d-flex flex-row"
                      style={{ border: 0 }}
                    >
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3 2C3.55228 2 4 2.44772 4 3V19C4 19.5523 4.44772 20 5 20H21C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H5C3.34315 22 2 20.6569 2 19V3C2 2.44772 2.44772 2 3 2ZM13.7573 5.00001L18 5C18.2652 5 18.5196 5.10536 18.7071 5.2929C18.8946 5.48044 19 5.73479 19 6.00001L19 10.2427C18.9999 10.7949 18.5522 11.2427 17.9999 11.2426C17.4477 11.2426 16.9999 10.7949 17 10.2426L17 8.41424L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L15.5858 7L13.7573 7.00001C13.205 7.00001 12.7573 6.55229 12.7573 6.00001C12.7573 5.44772 13.205 5.00001 13.7573 5.00001Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <p
                        className="mb-0 mt-1"
                        onClick={() => {
                          navigate("/subscription");
                        }}
                      >
                        {t("upgrade service")}
                      </p>
                    </li>
                    <li
                      className="list-group-item chat-bot-list-items d-flex flex-row"
                      style={{ border: 0 }}
                    >
                      <span className="me-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.355 4C11.8027 4 11.355 4.44771 11.355 5L11.355 6.60578L15.3815 10.9903C15.551 11.1748 15.645 11.4162 15.645 11.6667V16H17.355C17.9073 16 18.355 15.5523 18.355 15V8.66667H17.0693C15.4124 8.66667 14.0693 7.32352 14.0693 5.66667V4H12.355ZM16.0693 5.56729L17.0789 6.66667H17.0693C16.517 6.66667 16.0693 6.21895 16.0693 5.66667V5.56729ZM15.645 18H17.355C19.0119 18 20.355 16.6569 20.355 15V7.66667C20.355 7.41616 20.261 7.17477 20.0915 6.99026L15.8058 2.32359C15.6164 2.11738 15.3493 2 15.0693 2H12.355C10.6981 2 9.355 3.34314 9.35499 4.99999L9.35499 6H6.64502C4.98817 6 3.64502 7.34315 3.64502 9V19C3.64502 20.6569 4.98817 22 6.64502 22H12.645C14.3019 22 15.645 20.6569 15.645 19V18ZM13.645 12.6667H12.3593C10.7025 12.6667 9.35931 11.3235 9.35931 9.66667V8H6.64502C6.09273 8 5.64502 8.44772 5.64502 9V19C5.64502 19.5523 6.09273 20 6.64502 20H12.645C13.1973 20 13.645 19.5523 13.645 19V12.6667ZM11.3593 9.56729V9.66667C11.3593 10.219 11.807 10.6667 12.3593 10.6667H12.3689L11.3593 9.56729ZM6.65002 14.99C6.65002 14.4377 7.09774 13.99 7.65002 13.99H11.65C12.2023 13.99 12.65 14.4377 12.65 14.99C12.65 15.5423 12.2023 15.99 11.65 15.99H7.65002C7.09774 15.99 6.65002 15.5423 6.65002 14.99ZM6.65002 17.99C6.65002 17.4377 7.09774 16.99 7.65002 16.99H11.65C12.2023 16.99 12.65 17.4377 12.65 17.99C12.65 18.5423 12.2023 18.99 11.65 18.99H7.65002C7.09774 18.99 6.65002 18.5423 6.65002 17.99Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <HashLink
                        to="/#faqs"
                        style={{ textDecoration: "none" }}
                        smooth
                      >
                        <p className="mb-0 mt-1">{t("updates and faqs")}</p>
                      </HashLink>
                    </li>
                    <li
                      className="list-group-item chat-bot-list-items d-flex flex-row"
                      style={{ border: 0 }}
                    >
                      <span className="me-lg-3 me-md-3 me-sm-2 me-2 me5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="20"
                          viewBox="0 0 18 20"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.00003 2C6.79089 2 5.00003 3.79086 5.00003 6C5.00003 8.20914 6.79089 10 9.00003 10C11.2092 10 13 8.20914 13 6C13 3.79086 11.2092 2 9.00003 2ZM12.641 10.7694C14.075 9.67298 15 7.94452 15 6C15 2.68629 12.3137 0 9.00003 0C5.68632 0 3.00003 2.68629 3.00003 6C3.00003 7.94452 3.92504 9.67298 5.35904 10.7694C4.35357 11.2142 3.42854 11.8436 2.63607 12.636C2.02414 13.248 1.50938 13.939 1.1018 14.6851C0.349687 16.0619 0.656413 17.4657 1.50343 18.4584C2.31883 19.414 3.62505 20 5.00003 20H13C14.375 20 15.6812 19.414 16.4966 18.4584C17.3437 17.4657 17.6504 16.0619 16.8983 14.6851C16.4907 13.939 15.9759 13.248 15.364 12.636C14.5715 11.8436 13.6465 11.2142 12.641 10.7694ZM9.00003 12C7.14352 12 5.36304 12.7375 4.05028 14.0503C3.57428 14.5263 3.17397 15.0637 2.85699 15.6439C2.55002 16.2058 2.64905 16.7198 3.02484 17.1602C3.43224 17.6376 4.16587 18 5.00003 18H13C13.8342 18 14.5678 17.6376 14.9752 17.1602C15.351 16.7198 15.45 16.2058 15.1431 15.6439C14.8261 15.0637 14.4258 14.5263 13.9498 14.0503C12.637 12.7375 10.8565 12 9.00003 12Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                      <p onClick={handleLogout} className="mb-0 my-auto mt-1">
                        {t("logout")}
                      </p>
                    </li>
                    <li className="list-group-item chat-bot-list-items d-flex flex-row"
                      style={{ border: 0 }}>
                      <p onClick={() => {
                        setShowMessage(!showMessage)
                      }}>Unsubscribe</p>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-8 ps-2 mt-auto">
                  <div className="chat ">
                    <div
                      className={
                        selectMode == "dark"
                          ? "type-here-dark d-flex flex-row align-items-center  me-auto mt-auto"
                          : "type-here-light d-flex flex-row align-items-center  me-auto mt-auto"
                      }
                    >
                      <div className="yugen-logo-t p-2">
                        <div
                          className={
                            selectMode === "dark"
                              ? "username-dark d-flex justify-content-center align-items-center"
                              : "username-light d-flex justify-content-center align-items-center"
                          }
                        >
                          <span className="">
                            {user?.firstName?.charAt(0).toUpperCase()}
                          </span>
                        </div>
                      </div>
                      <div className="input-group chat-box">
                        <input
                          ref={inputRef}
                          type="text"
                          className="form-control my-auto position-relative"
                          placeholder=""
                          aria-label="Example text with button addon"
                          aria-describedby="button-addon1"
                          value={newMessage}
                          onChange={(e) => {

                            // setTimeout(()=>{
                            setNewMessage(e.target.value)
                          }}
                          onKeyDown={handleChatUpdate}
                          disabled={!inputToggle}
                        />

                        {isLoading && (
                          <span
                            className={
                              selectMode === "dark"
                                ? "loading-dots-dark"
                                : "loading-dots-light"
                            }
                          >
                            <div className="host d-flex flex-row h-100 align-items-center">
                              <div className="loading loading-0"></div>
                              <div className="loading loading-1"></div>
                              <div className="loading loading-2"></div>
                            </div>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Modal show={showMessage}>
        <Modal.Header>
          <Modal.Title>Confirmation Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 style={{ marginBottom: '20px', fontSize: '20px', fontWeight: 'bold', color: '#333' }}>
            Are you sure you want to Unsubscribe?
          </h5>        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              style={{
                margin: '0 10px',
                padding: '10px 20px',
                fontSize: '16px',
                backgroundColor: 'rgba(239, 9, 161, 0.8705882353)', // Button background color
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => { unsubsribeSubscription(true) }}
            >
              Yes
            </button>
            <button
              style={{
                margin: '0 10px',
                padding: '10px 20px',
                fontSize: '16px',
                backgroundColor: 'rgba(239, 9, 161, 0.8705882353)', // Button background color
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => { unsubsribeSubscription(false) }}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
