import React from "react";
import "./style/style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import LandingPage from "./@modules/LandingPage";
import LoginPage from "./@modules/LoginPage";
import SignUpPage from "./@modules/SignUpPage";
import SubscriptionPage from "./@modules/SubscriptionPage";
import TermsAndServicePage from "./@modules/TermsAndServicePage";
import ContactUsPage from "./@modules/ContactUs";
import AboutUsPage from "./@modules/AboutUsPage";
import UserAccountPage from "./@modules/UserAccountPage";
import UpcomingEventsPage from "./@modules/UpcomingEventsPage";
import ChatBotPage from "./@modules/ChatBotPage";
// import { ToastContainer, toast } from "react-toastify";
import { Toaster, toast } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import CheckoutForm from "./@modules/CheckoutForm";
import { PremiumRoutes, ProtectedRoutes } from "./ProtectedRoutes";

import "./i18n";
import PrivacyPolicy from "./@modules/PrivacyPolicy";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import VerifyEmail from "./@modules/VerifyEmail";
import VerifyRegistration from "./@modules/VerifyRegistration";
import ForgotPassword from "./@modules/ForgotPassword";
import ResetPassword from "./@modules/ResetPassword";
import SuccessPage from "./@modules/SuccessPage";
import CancelPage from "./@modules/CancelPage";
const stripePromise = loadStripe(
  "pk_live_51NAYgpHEVzGpc74rSfjrPibIu5g3t7gEHreI5crIKImcAtOLIX5ugTJMm2CGeZyXttMeQwu4xobg1MJi6AguE4le00me7CSFPu"
);

const queryClient = new QueryClient();
function App() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/verify" element={<VerifyEmail />} />
            <Route path="/verified" element={<VerifyRegistration />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/cancel" element={<CancelPage />} />

            <Route
              path="/subscription"
              element={
                <Elements stripe={stripePromise}>
                  <SubscriptionPage />
                </Elements>
              }
            />
            <Route path="/success" element={<SuccessPage/>}/>
            <Route path="/term-and-service" element={<TermsAndServicePage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/user-account" element={<UserAccountPage />} />
            <Route path="/upcoming-events" element={<UpcomingEventsPage />} />
            <Route element={<PremiumRoutes />}>
              <Route path="/chat-bot" element={<ChatBotPage />} />
            </Route>
            {/* <Route path="/checkout-form" element={<Elements stripe={stripePromise}><CheckoutForm /></Elements>} /> */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
        <Toaster position="top-right" reverseOrder={true} />
        {/* <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
