import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import LoginPage from "./@modules/LoginPage";
import { useSelector } from "react-redux";
import { selectUser } from "./auth/AuthSlice";
import ChatBotPage from "./@modules/ChatBotPage";

const useAuth = () => {
  const user = useSelector(selectUser);
  const authToken = user.token;
  // const user = {loggedIn: false}
  return authToken;
};

const useStatus = () => {
  const user = useSelector(selectUser);
  const userStatus = user.Status;
  return userStatus;
};
export const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export const PremiumRoutes = () => {
  const user = useSelector(selectUser);

  // if (status !== "paid") {
  //   navigate("/subscription#");
  //   return null; // Render nothing while navigating
  // }

  return user.status != "Paid" && user.trial != "y" && user.trial !== "" ? (
    <Navigate to="/subscription#" />
  ) : (
    <Outlet />
  );
};
